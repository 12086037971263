div.page-headers {
    display: inline-block;
    margin: auto;
    border-radius: 0 0 80px 80px;
    background: #22baa9;
  }
  
  p.page-headers-text {
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0;
    font-family: B Koodak Bold_0;
    display: block;
    font-weight: 700;
    font-size: 40px;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    /* Adjust styles for screens up to 768px wide */
    p.page-headers-text {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 30px;
    }
  }
  
  @media (max-width: 480px) {
    /* Adjust styles for screens up to 480px wide */
    p.page-headers-text {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 20px;
    }
  }