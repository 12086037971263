.Cogmo-Dash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #fbc2eb, #a6c1ee);
    color: #333;
    font-family: IranSans, sans-serif;
    padding: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .Cogmo-Dash-header {
    background: linear-gradient(90deg, #ccff75, #f67eff);
    color: rgb(12, 82, 71);
    padding: 20px 30px;
    border-radius: 15px;
    font-weight: bolder;
    font-size: larger;
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
    direction: rtl;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    animation: headerGlow 3s infinite alternate;
  }
  
  @keyframes headerGlow {
    from { box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); }
    to { box-shadow: 0 4px 25px rgba(204, 255, 117, 0.6); }
  }
  
  .Cogmo-Dash-user-name {
    font-weight: bold; 
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #444;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .Cogmo-Dash-profile {
    text-align: center;
    margin-bottom: 40px;
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .Cogmo-Dash-profile:hover {
    transform: scale(1.03);
  }
  
  .Cogmo-Dash-profile-avatar {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    border: 6px solid #75ffdd;
    margin-bottom: 10px;
    animation: avatarPop 1s;
  }
  
  @keyframes avatarPop {
    0% { transform: scale(0.8); }
    60% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .Cogmo-Dash-user-status {
    margin-top: 20px;
    padding: 12px;
    background: #ffecf1;
    border: 1px solid #ffb6c1;
    border-radius: 10px;
    font-style: italic;
    margin-bottom: 10px;
    direction: rtl;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .Cogmo-Dash-games {
    margin-bottom: 20px;
    text-align: center;
    direction: rtl;
    background: rgba(255, 255, 255, 0.9);
    padding: 25px;
    padding-top: 5px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .Cogmo-Dash-game-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    direction: rtl;
    flex-wrap: wrap;
  }
  .Cogmo-Dash-games-notice {
    padding: 20px;
    text-align: center;
    font-weight: bolder;
    font-size: 1.2em;
    direction: rtl;
    color: #333;
  }
  .Cogmo-Dash-game-btn {
    background: linear-gradient(45deg, #ff6f61, #ff8e53);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    direction: rtl;
    font-weight: bold;
  }
  
  .Cogmo-Dash-game-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  .Cogmo-Dash-partner-logos {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 20px;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
  }
  
  .Cogmo-Dash-partner-logos img {
    width: 90px;
    height: auto;
    transition: transform 0.3s;
  }
  
  .Cogmo-Dash-partner-logos img:hover {
    transform: scale(1.1);
  }
  
  .Cogmo-Dash-recommendation-notice {
    background: #e0f7fa;
    padding: 25px 35px;
    border-radius: 15px;
    text-align: center;
    direction: rtl;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .Cogmo-Dash-action-btn {
    background: linear-gradient(45deg, #4caf50, #8bc34a);
    color: white;
    border: none;
    margin-top: 15px;
    padding: 15px 30px;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    direction: rtl;
    font-weight: bold;
    font-size: 16px;
  }
  
  .Cogmo-Dash-action-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    .Cogmo-Dash-container {
      flex-direction: column;
      padding: 15px;
    }
  
    .Cogmo-Dash-profile-avatar {
      width: 100px;
      height: 100px;
    }
  
    .Cogmo-Dash-game-btn {
      font-size: 14px;
      padding: 10px 15px;
    }
  
    .Cogmo-Dash-action-btn {
      padding: 10px 20px;
      font-size: 14px;
    }
  }