@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

:root {
  --grid-size: 4; /* Default size */
  --corsi-bg-color-dark: #1b212d;
  --corsi-text-color-dark: #eff3fd;
  --corsi-grid-bg-dark: #202534;
  --corsi-border-color-dark: #2a3240;
  --corsi-highlight-color-dark: #fead53;
  --corsi-correct-color-dark: #4caf50;
  --corsi-button-bg-dark: linear-gradient(45deg, #11998e, #38ef7d);
  --corsi-progress-bar-dark: linear-gradient(45deg, #1a4d60, #25637a);

  /* Light Theme Colors */
  --corsi-bg-color-light: #f9f9f9;
  --corsi-text-color-light: #333;
  --corsi-grid-bg-light: #ffffff;
  --corsi-border-color-light: #ddd;
  --corsi-highlight-color-light: #f39c12;
  --corsi-correct-color-light: #2ecc71;
  --corsi-button-bg-light: linear-gradient(45deg, #3498db, #85c1e9);
  --corsi-progress-bar-light: linear-gradient(45deg, #3498db, #85c1e9);
}

  .game-page{
    background-color: #1b212d;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .corsi-test h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .status-bar {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  .block {
    width: min(90px, 10vw);
    height: min(90px, 10vw);
    background-color: #39879c;
    border-radius: 20%;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  }

  .grid-resizing {
    animation: resizeGrid 0.5s ease-out;
  }
  
  @keyframes resizeGrid {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  
  .block.highlighted {
    background-color: #fead53;
    transform: scale(1.2);
  }
  
  
  .block:hover {
    transform: scale(1.1);
  }
  
  .block.correct {
    background-color: #4caf50;
    border-color: #388e3c;
    transform: scale(1.2);
  }
  
  
  .speed-selection {
    margin-top: 20px;
  }
  
  .speed-selection p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .speed-btn {
    padding: 10px 15px;
    margin: 5px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .speed-btn.selected {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .speed-btn:hover {
    background-color: #0056b3;
  }
  
  
  .retry-prompt {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff8e1;
    border: 1px solid #fdd835;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .retry-prompt p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .retry-prompt button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    color: #fff;
    background-color: #0288d1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .retry-prompt button:hover {
    background-color: #0277bd;
  }
  
  .retry-prompt button:nth-child(2) {
    background-color: #d32f2f;
  }
  
  .retry-prompt button:nth-child(2):hover {
    background-color: #c62828;
  }
  
  .corsi-test {
    text-align: center;
    font-family: IranSans;
    background-color: #202534;
    padding: 20px;
    border-radius: 10px;
    border: 3px solid #2a3240;
    min-width: 70%;
    max-width: 90%;
    min-height: 550px;
    margin: auto;
    position: relative;
    padding-top: 60px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .formula-corsi{
    font-family: IranSans;
  }

  .instructions-list li{
    text-align: right;
    direction: rtl;
  }

  .corsi-test h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .status-bar {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .grid-corsi {
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    margin: 20px auto;
    grid-template-columns: repeat(var(--grid-size), min(90px, 10vw));
    transition: grid-template-columns 0.5s ease, grid-template-rows 0.5s ease;
    max-width: 100%; /* Prevent overflow */
  }  

  .grid-corsi.hidden {
    opacity: 0; /* Hidden grid with 0 opacity */
  }
  
  .grid-corsi.visible {
    opacity: 1; /* Visible grid with full opacity */
  }
  
  
  .instructions {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .instructions h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 15px;
    color: #eff3fd;
  }
  
  .instructions ul {
    list-style: none;
    text-align: left;
    margin: 0 auto;
    max-width: 400px;
    padding-left: 0;
  }
  
  .instructions ul li {
    margin: 10px 0;
    font-size: 18px;
    color: #eff3fd;
    position: relative;
    padding-right: 30px;
  }
  
  .instructions ul li::before {
    content: "✓";
    position: absolute;
    right: 0;
    top: 2px;
    color: #4caf50;
    font-weight: bold;
    font-size: 18px;
  }
  
  .instructions p {
    font-size: 16px;
    color: #eff3fd;
    margin-bottom: 10px;
  }
  
  .instructions code {
    display: block;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    margin: 10px auto;
    text-align: center;
    color: #333;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
  }

  .button-group:last-child {
    margin-bottom: 60px;
  }

  .speed-text-choose{
    margin-top: 25px;
  }
  
  .grid-btn,
  .speed-btn {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .grid-btn {
    background: linear-gradient(45deg, #ff7e5f, #feb47b);
    color: white;
    box-shadow: 0px 4px 6px rgba(255, 126, 95, 0.4);
  }
  
  .grid-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(255, 126, 95, 0.6);
  }
  
  .grid-btn.selected {
    background: linear-gradient(45deg, #ff512f, #dd2476);
    box-shadow: 0px 4px 6px rgba(221, 36, 118, 0.6);
    transform: scale(1.1);
  }
  
  .speed-btn {
    background: linear-gradient(45deg, #36d1dc, #5b86e5);
    color: white;
    box-shadow: 0px 4px 6px rgba(91, 134, 229, 0.4);
  }
  
  .speed-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(91, 134, 229, 0.6);
  }
  
  .speed-btn.selected {
    background: linear-gradient(45deg, #005bea, #00c6fb);
    box-shadow: 0px 4px 6px rgba(0, 198, 251, 0.6);
    transform: scale(1.1);
  }
  
  .start-btn {
    padding: 12px 20px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #11998e, #38ef7d);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(56, 239, 125, 0.4);
    display: flex;
    direction: rtl;
  
  }
  
  .start-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(56, 239, 125, 0.6);
  }



  .user-icon {
    font-size: 18px; /* Size of the user icon */
    color: #99a2bd; /* Color of the user icon */
  }


  .stat {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    gap: 10px;
  }
  
  .heart {
    font-size: 18px;
  }

  .lives {
    display: flex; /* Arrange hearts in a row */
    gap: 5px; /* Space between each heart */
  }

  .game-description-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    direction: rtl;
    padding: 20px;
  }

  .corsi-pre-level-page,
.corsi-post-level-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  direction: rtl;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.corsi-pre-level-title {
  font-size: 1.5rem;
  color: #eff3fd;
  margin-bottom: 10px;
}

.corsi-pre-level-text {
  font-size: 1.5rem;
  color: #eff3fd;
}

.corsi-result-image {
  max-height: 250px;
  width: auto;
  margin-bottom: 20px;
}

.corsi-result-image-desc {
  max-height: 200px;
  width: auto;
  margin-bottom: 20px;
}

.corsi-result-image-lose {
  max-height: 350px;
  width: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .corsi-result-image {
    max-height: 150px;
  }
  .corsi-result-image-desc {
    max-height: 130px;
  }
  .corsi-result-image-lose {
    max-height: 250px;
  }
}

.corsi-result-message {
  font-family: IranSans;
  font-size: 1.5rem;
  color: #eff3fd;
  margin: 0;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .corsi-result-message {
    font-size: 1.3rem;
  }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
  .corsi-result-message {
    font-size: 1.1rem;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .corsi-result-message {
    font-size: 1rem;
  }
}


.corsi-post-level-page.correct {
  background-color: #eaffea; /* Light green for correct */
}

.corsi-post-level-page.wrong {
  background-color: #ffeaea; /* Light red for wrong */
}

.instructions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap; /* Ensures responsiveness */
}

.grid-preview {
  flex: 2;
  display: flex;
  justify-content: center;
  /* min-width: 300px; */
}

.instructions {
  flex: 1;
  max-width: 300px;
  min-width: 200px;
  text-align: justify;
}

/* Instruction Box with Animation */
.instruction-box {
  margin-bottom: 30px;
  position: relative;
  direction: rtl;
}

/* Fade-out animation */
.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Fade-in animation */
.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Button Styles */
.next-step-btn{
  display: block;
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.next-step-btn:hover {
  background-color: #2980b9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .instructions-container {
    flex-direction: column;
    align-items: center;
  }

  .grid-preview, .instructions {
    width: 100%;
    text-align: center;
  }
}

.navigation-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.nav-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 10px;
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color: white;
  box-shadow: 0px 4px 6px rgba(255, 126, 95, 0.4);
}

.nav-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(255, 126, 95, 0.6);
}

.nav-btn:disabled {
  background: gray;
  cursor: not-allowed;
  box-shadow: none;
}

.repeat-btn {
  background: linear-gradient(45deg, #36d1dc, #5b86e5);
}

.repeat-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(91, 134, 229, 0.6);
}


/* Progress Line */
.progress-line-container {
  position: absolute; /* Position it at the bottom of the container */
  bottom: 0; /* Stick it to the bottom */
  left: 0; /* Align it to the left */
  width: 100%; /* Make it span the full width */
  height: 5px; /* Set the height of the progress bar */
  border-radius: 10px;
  margin-top: 20px; /* Optional space between the instructions/buttons and the progress bar */
}

.progress-line {
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(45deg, #1a4d60, #25637a);  /* Example gradient */
  transition: width 0.5s ease-in-out;
}

/* Lose Image and Text */
.lose-image-container {
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.lose-image {
  width: 100%;
  max-width: 300px;
  animation: zoomIn 0.5s ease-in-out;  /* Add animation to the image */
}

.lose-text {
  font-size: 18px;
  font-weight: bold;
  color: #ff4d4d;
  margin-top: 15px;
  animation: fadeIn 1s ease-in-out;
}

/* Animation for zooming the lose image */
@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.settings{
  direction: rtl;
}

.settings p{
  color: #eff3fd;
  text-align: center;
}

.game-over-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.game-over-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.game-over-message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.retry-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff5722;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.retry-btn:hover {
  background-color: #e64a19;
}

.game-description {
  /* padding: 20px; */
  border-radius: 15px;
  text-align: center;
  color: #fff;
  max-width: 600px;
  margin: auto;
  transition: all 0.3s ease-in-out;
  direction: rtl;
}

.game-description p {
  font-size: 1rem;
  /* margin-bottom: 10px; */
  text-align: justify;
}

.tutorial-option {
  margin-top: 15px;
}

.tutorial-option label {
  font-size: 1rem;
  cursor: pointer;
}

.tutorial-option input {
  margin-right: 5px;
}

/* Animations */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.grid-container {
  width: 100%;
  overflow-x: auto; /* Allows scrolling if needed */
  display: flex;
  justify-content: center;
}

.grid-container.game{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1100px) {
  .grid-corsi {
    grid-gap: 10px;
  }

  .block {
    width: min(70px, 12vw);
    height: min(70px, 12vw);
  }
}

@media (max-width: 900px) {

  .instructions{
    max-width: 150px;
  }

  .grid-corsi {
    grid-gap: 8px;
  }

  .block {
    width: min(60px, 10vw);
    height: min(60px, 10vw);
  }
}

@media (max-width: 768px) {

  .instructions{
    min-width: 250px;
    max-width: 400px;
    margin-top: 0;
    padding: 0;
  }

  .grid-corsi {
    grid-gap: 8px;
  }

  .block {
    width: min(60px, 10vw);
    height: min(60px, 10vw);
  }

  .grid-container.game{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}

@media (max-width: 480px) {

  .instructions{
    min-width: 200px;
    max-width: 200px;
  }
}

/* Dark Theme */
.corsi-dark-theme {
  background-color: var(--corsi-bg-color-dark);
  color: var(--corsi-text-color-dark);
}

.corsi-dark-theme .corsi-test {
  background-color: var(--corsi-grid-bg-dark);
  border-color: var(--corsi-border-color-dark);
}

.corsi-dark-theme .block {
  background-color: #39879c;
}

.corsi-dark-theme .block.highlighted {
  background-color: var(--corsi-highlight-color-dark);
}

.corsi-dark-theme .block.correct {
  background-color: var(--corsi-correct-color-dark);
}

.corsi-dark-theme .start-btn {
  background: var(--corsi-button-bg-dark);
}

.corsi-dark-theme .progress-line {
  background: var(--corsi-progress-bar-dark);
}

/* Light Theme */
.corsi-light-theme {
  background-color: var(--corsi-bg-color-light);
  color: var(--corsi-text-color-light);
}

.corsi-light-theme .corsi-test {
  background-color: var(--corsi-grid-bg-light);
  border-color: var(--corsi-border-color-light);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.corsi-light-theme .block {
  background-color: #dfe6e9;
  border: 2px solid #ccc;
}

.corsi-light-theme .block.highlighted {
  background-color: var(--corsi-highlight-color-light);
}

.corsi-light-theme .block.correct {
  background-color: var(--corsi-correct-color-light);
}

.corsi-light-theme .start-btn {
  background: var(--corsi-button-bg-light);
}

.corsi-light-theme .progress-line {
  background: var(--corsi-progress-bar-light);
}

.corsi-light-theme .corsi-pre-level-text, .corsi-light-theme .corsi-post-level-text {
  color: var(--corsi-text-color-light);
}

.corsi-light-theme .tutorial-option, .corsi-light-theme .instruction-step, .corsi-light-theme .settings p {
  color: var(--corsi-text-color-light);
}

.instruction-step{
  text-align: justify;
}

.corsi-light-theme .corsi-result-message, .corsi-light-theme .stat span{
  color: var(--corsi-text-color-light);
}

.corsi-light-theme .game-page{
  background-color: var(--corsi-bg-color-light);
}

.corsi-light-theme .corsi-header{
  background-color: var(--corsi-bg-color-light);
}

.corsi-theme-toggle {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 10px 15px;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4);
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.corsi-theme-toggle:hover {
  background: linear-gradient(45deg, #ff758c, #ff7eb3);
}

.corsi-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #1e2332;
  padding: 10px 15px;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: auto;
  border: 2px solid #373d50;
  z-index: 10;
  gap: 15px;
  direction: rtl;
  flex-wrap: wrap; /* Allow wrapping */
}

.corsi-header div {
  font-family: IranSans;
  font-size: 14px;
  color: #edf2f5;
  display: flex;
  align-items: center;
  width: auto;
}

.corsi-header div span {
  font-weight: bold;
}

.corsi-theme-toggle {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 10px 15px;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4);
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.corsi-theme-toggle:hover {
  background: linear-gradient(45deg, #ff758c, #ff7eb3);
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .corsi-header {
    flex-direction: column;
    align-items: stretch;
    position: static;
    width: 100%;
    gap: 0px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .corsi-header div {
    justify-content: space-between;
    padding: 5px;
    /* border: 1px solid #373d50; */
    border-radius: 15px;
    width: 100%;
  }

  .corsi-theme-toggle {
    position: static;
    width: 100%;
    text-align: center;
  }

  .corsi-test {
    padding-top: 10px;
  }
}
