
  
  div.flex-container-signin-input {
    display: flex;
    width: 100%;
    margin: auto;
  }
  
  div.flex-child-signin-input {
    flex: 1;
    background: #6336F0;
  }  
  
  div.flex-child-signin-input {
    margin-top: 1%;
    margin-bottom: 1%;
  } 
  
  div.flex-child-signin-input:first-child {
    flex-grow: 1.5;
    /* margin-right: 5%; */
    margin-left: 2%;
  } 
  
  
  
  div.flex-child-signin-input:nth-child(2) {
      flex-grow: 1;
      /* margin-left: 5%; */
  } 
  
  p.sigin-text{
    line-height: 28px;
    font-size: 13px;
    color: white;
    text-align: justify;
    text-justify: inter-word;
    
    width: 80%;
  }
  
  div.signin-base{
      text-align: center;
      margin: auto;
  }
  
  div.upper-div{
    text-align: center;
    align-items: center;
  }
  
  div.centered-div{
    /* position: absolute; */
    margin: auto;
    justify-content: center;
    text-align: center;
    display: flex;
    right: 45%;
    height: 50vh;
  }
  
  
    #mobile-signin{
      border-radius: 11px;
      display: grid;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 4%;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
      font-size: 20px;
      width: 40%;
      outline: none;
      border-style: solid;
      border-width: 3px;
      border-color: #F9B33D;
    }
  
  
    ul.signin-items {
      text-align: center;
      display:inline;
      list-style-type: none;
    }
    ul.signin-items > li {
      display: inline-block;
      /* padding: 10px 20px; */
      width: 30%;
    }
  
    button.sub-btn-si{
      display: grid;
      margin: auto;
      margin-top: 3%;
      /* padding-top: 1.5%; */
      /* padding-bottom: 1.5%; */
      width: 15%;
      background: #069279;
      border-radius: 15px;
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      line-height: 31px;
      color: white;
      border: none;
      outline: none;
    }
  
    button.sub-btn-si:hover{
      background: #469b8c;
    }
  
    div.signin-helper{
      display: grid;
      margin: auto;
      text-align: center;
      width: 15%;
      font-size: 20px;
      background: #bfd310;
      border: 4px solid #161C38;
      border-radius: 29px;
    }
  
    img.moshak-image{
      margin-top: 15px;
      width: 180px;
      height: 150px;
    }
  
    div.verify-modal{
      position: fixed;
      text-align: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
    }
  
    section.modal-main-verify-modal{
      position: fixed;
      background-color: white;
      height: auto;
      border-radius: 25px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30%;
      /* height: 60vh; */
    }
  
    div.logo-image{
      border-radius: 100%;
      width: 150px;
      height: 150px;
      border-style: solid;
      border-width: 4px;
      border-top-color: #7F00FF;
      border-left-color: #7F00FF;
      border-right-color: #F9B33D;
      border-bottom-color: #F9B33D;
      text-align: center;
      align-items: center;
      margin: auto;
    }
  
    div.verify-content{
      margin-top: 7%;
    }
  
    p.h1-verify{
      font-weight: 400;
      font-size: 18px;
      margin: 5%;
    }
  
    button.sub-btn-verify{
      background: #2C9D89;
      border-radius: 30px;
      display: grid;
      margin: auto;
      margin-top: 6%;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
      font-weight: 400;
      font-size: 20px;
      color: #FEFEFE;
      width: 20%;
      text-align: center;
      border: none;
      outline: none;
      margin-left: 38%;
      margin-bottom: 5%;
    }
  
    button.sub-btn-verify:hover{
      background: #069279;
      border-radius: 30px;
      display: grid;
      margin: auto;
      margin-top: 6%;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
      font-weight: 400;
      font-size: 20px;
      color: #FEFEFE;
      width: 20%;
      text-align: center;
      border: none;
      outline: none;
      margin-left: 38%;
      margin-bottom: 5%;
    }
  
    #otp-code{
      /* border-radius: 100%; */
      /* background: #bbddc3; */
      /* border-style: solid; */
      border: 2px solid #6336F0;
      font-family: IranSans;
      width: 20%;
      padding: 5px;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #000;
      margin-right: 1%;
      outline: none;
      justify-content: center;
      text-align: center;
      color: black;
    }
  
    form.form-otp{
      direction: ltr;
    }
  
    div.xmark-verify{
      padding: 0;
      margin: 0;
      float: right;
      margin-right: 5px;
    }
  
    div.send-again{
      float: right;
      margin-right: 15%;
    }
  
    p.send-again{
      font-size: 10px;
    }
  
    p.send-again:hover{
      font-size: 15px;
      color: red;
      cursor: pointer;
    }
    
    div.send-again1{
      position: absolute;
      bottom: -80%;
      right: 60px;
      
    }
    p.send-again1{
      font-family: IranSans;
      font-weight: 700;
      font-size: 10px;
      text-align: center;
      color: #333;
    }
  
    p.send-again1:hover{
      cursor: pointer;
    }
  
    div.no-number{
      position: absolute;
      top: 100%;
      right: 40px;
    }
    
    p.no-number{
      font-family: IranSans;
      font-weight: 400;
      font-size: 10px;
      text-align: center;
      color: #f90000;
      animation: blinker 2s linear infinite;
    }
  
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  
    div.no-code{
      position: absolute;
      right: 30%;
      margin-top: 2%;
    }
  
    p.no-code{
      font-family: IranSans;
      font-weight: 400;
      font-size: 10px;
      text-align: center;
      color: #f90000;
      animation: blinker 2s linear infinite;
    }
    .flex-container-signin{
      display: flex;
      margin: auto;
      width: 90%;
      direction: rtl;
    }
  
    div.flex-child-signin {
      flex: 1;
    }  
  
    div.flex-child-signin:first-child {
      position: relative;
    }  
  
    div.flex-child-signin:nth-child(2) {
      position: relative;
    }  
  
    div.flex-child-signin:nth-child(3) {
      position: relative;
    }  
  
    input.inputs{
      padding: 8px;
      font-size: 14px;
      color: #797979;
      /* width: 100%; */
      text-align: center;
      border-radius: 10px;
      border: 1px solid #DFDEDE;
  }

  .input-labels {
    position: absolute;
    right: 1.5em;
    top: -0.8em;
    background: #f9f9f9;
    text-align: right;
    padding: 0 0.5em;
    margin-bottom: 0.5em;
    font-family: IranSans;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #6336F0;
  }
  
  div.input-group{
      font-family: IranSans;
      position: relative;
      width: 100%;
      /* max-width: 25em; */
      display: flex;
      /* flex-direction: column; */
      z-index: 2;
      /* display: flex; */
  }

  
    .send-code-button.enabled {
      background-color: #6336F0;
      padding: 10px;
      font-size: 14px;
      color: #797979;
      /* width: 100%; */
      text-align: right;
      border-radius: 10px;
      border: 1px solid #DFDEDE;
      color: white;
      font-family: IranSans;
      text-align: center;
      cursor: pointer;
    }
    
    .send-code-button.disabled {
      background: #959496;
      padding: 10px;
      font-size: 14px;
      color: white;
      /* width: 100%; */
      text-align: right;
      border-radius: 10px;
      border: 1px solid #DFDEDE;
      color: white;
      font-family: IranSans;
      text-align: center;
      cursor: not-allowed;
    }

    .submit-verification-code{
      margin-top: 50px !important;
      margin: auto;

    }
  
    .submit-verification-code.enabled{
      background-color: #6336F0;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 14px;
      cursor: pointer;
      font-size: 12px;
      font-family: IranSans;
      text-align: center;
      cursor: pointer;
    }

    .submit-verification-code:hover{
      background-color: #7d57f0;
    }
  
    .submit-verification-code.disabled{
      background-color: #959496;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 14px;
      cursor: pointer;
      font-size: 12px;
      margin-top: 15px;
      font-family: IranSans;
      border-radius: 20px;
      cursor: not-allowed;
    }

    .sigin-verify-div{
      margin-top: 5%;
    }


    .flex-container-signin {
      display: flex;
      justify-content: space-between; /* Adds space between the flex items */
      align-items: center; /* Aligns flex children vertically in the center */
      margin: auto;
      width: 100%;
      max-width: 600px; /* Sets a max width for responsiveness */
      padding: 20px;
      direction: rtl;
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
      background-color: #f9f9f9;
      border-radius: 10px;
    }
    
    div.flex-child-signin {
      flex: 1 1 45%; /* Allows the children to shrink/grow based on space, with a base size of 45% */
      margin: 10px;
      /* display: flex; */
      flex-direction: column; /* Aligns content vertically inside each child */
    }

    .form-otp {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
    
    .form-control {
      width: 60px;
      padding: 10px;
      margin-right: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      text-align: center;
      font-size: 1.5rem;
    }

    /* Responsive Design */
  @media (max-width: 960px) {
  .flex-container-signin {
    flex-direction: column; /* Stack the flex children vertically on smaller screens */
    width: 100%;
    padding: 10px;
  }

  .flex-child-signin {
    width: 100%; /* Make each flex child take full width */
    margin: 5px 0;
  }

  .form-control {
    width: 45px; /* Make OTP fields smaller on small screens */
  }

  input.inputs{
    width: 100%;
}
}

.switch-auth-container {
  text-align: center;
  margin-top: 30px;
}

.switch-auth-button {
  background-color: #6336F0;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 12px;
  font-family: IranSans;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.switch-auth-button:hover{
  background-color: #7d57f0;
}