section.modal-main-delete {
    position: fixed;
    background-color: white;
    height: auto;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 30px 20px;
    animation: popupAnimation 0.3s ease-out forwards;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    direction: rtl;
    text-align: center;
}

div.modal-delete {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.delete-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

button.delete-confirm, button.delete-cancel {
    font-family: IranSans;
    font-size: 16px;
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
}

button.delete-confirm {
    background: linear-gradient(45deg, #ff6b6b, #ff0000);
    color: white;
    border: none;
    box-shadow: 0 4px 12px rgba(255, 107, 107, 0.4);
}

button.delete-confirm:hover {
    background: linear-gradient(45deg, #ff4a4a, #ff0000);
    box-shadow: 0 6px 16px rgba(255, 107, 107, 0.6);
}

button.delete-cancel {
    background: white;
    color: #808080;
    border: 2px solid #808080;
}

button.delete-cancel:hover {
    color: black;
    border-color: black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

p.delete-text {
    font-family: IranSans;
    font-weight: 500;
    font-size: 20px;
    color: #333;
    margin-bottom: 16px;
}

.warning-text {
    font-family: IranSans;
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
}

.delete-divider {
    border: none;
    width: 80%;
    margin: 20px auto;
    border-top: 1px solid #ddd;
}

/* Responsive styles - similar adjustments for smaller screens */
@media (max-width: 1100px) {
    section.modal-main-delete {
        width: 90%;
        padding: 20px;
    }

    p.delete-text {
        font-size: 18px;
    }

    .warning-text {
        font-size: 12px;
    }

    button.delete-confirm, button.delete-cancel {
        font-size: 14px;
        padding: 10px 20px;
    }

    .delete-buttons {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 480px) {
    section.modal-main-delete {
        width: 95%;
        padding: 20px;
    }

    .delete-buttons {
        flex-direction: column;
        gap: 10px;
    }

    button.delete-confirm, button.delete-cancel {
        width: 100%;
        padding: 12px 0;
        font-size: 12px;
    }
}
