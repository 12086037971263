/* Popup.css */
@font-face {
    font-family: IranSans;
    src: url("../fonts/IranSans.ttf");
  }
  
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    font-family: IranSans !important;
  }
  
  .PopupContainer {
    width: 80%;
    max-width: 768px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .CityImage {
    position: relative;
    width: 100%;
    height: 275px;
    background-size: cover;
    background-position: center center;
  }
  
  .DoctorImage {
    position: absolute;
    left: 30px;
    bottom: 0px;
    width: 200px;
    height: auto;
  }

  .Details {
    position: absolute;
    right: 100px;
    bottom: 140px;
    width: 300px; /* Adjust this value if needed */
    background: #fdfaf3; /* Optional: add background to make text readable */
    padding: 10px;
    border-radius: 28px;
    box-shadow: 0 2px 5px 5px #dfd3bd;
  }
  
  .Details .DoctorName {
    margin: 0;
    font-size: 1.5em;
  }
  
  
  .Info {
    margin: 2px 0;
    font-size: 14px;
  }

  div.modal-packaging{
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: FontsFree-Net-ir_sans !important; */
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    font-family: IranSans !important;

  }

  section.modal-main-packaging{
    position: fixed;
    background-color: #fdfaf1;
    height: auto;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    z-index: 1;
    font-family: IranSans !important;
    box-sizing: border-box;
    border: 16px solid #2c4250;
    /* height: 50vh; */
  }

  section.modal-main-packaging-result{
    position: fixed;
    background-color: #fdfaf1;
    height: auto;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    z-index: 1;
    font-family: IranSans !important;
    box-sizing: border-box;
    border: 16px solid #2c4250;
    height: 90vh;
  }

  @media screen and (max-width: 768px) {
    section.modal-main-packaging {
      width: 90%;
    }
    section.modal-main-packaging-result {
        width: 90%;
      }
  }
  
  @media screen and (max-width: 480px) {
    section.modal-main-packaging {
      width: 95%;
    }
  }

  .modal-main-packaging {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    /* width: 400px; */
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .modal-main-packaging-result {
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 5px;
    /* width: 400px; */
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 600px) {
    .modal-main-packaging {
      width: 100%;
      max-width: 90%;
    }
  }

  .items-list-div {
    direction: rtl;
    margin-top: 20px;
    font-family: IranSans !important;

  }

  ul.items-list-ul{
    direction: rtl;
    text-align: right;
    list-style-type: none;
    width: 100%;
    font-family: IranSans !important;

  }

  ul.items-list-ul > li{
    margin: 10px;
    font-family: IranSans !important;

    /* align-items: center;
    text-align: center; */
  }

  .items-list-ul {
    list-style: none;
    padding: 0;
  }
  
  .items-list-ul li {
    margin-bottom: 10px;
    color: #333;
  }

  .select-label-figma{
    color: #797979;
    font-size: 15px;
  }

  .select-label-figma1{
    color: #797979;
    font-size: 18px;
    font-family: IranSans !important;

  }

  .filter-elements {
    padding: 8px;
    /* background-color: #f5f5f5; */
    font-size: 16px;
    color: #797979;
    width: 100%;
    text-align: right;
    border-radius: 10px;
    border: 1px solid #DFDEDE;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("../images/arrow.png") no-repeat 5% 50%;
    background-size: 6.3px 10.3px;
    font-family: IranSans !important;
  }

  p.package-desc{
    direction: rtl;
    text-align: justify;
    font-size: 18px;
  }

  .react-icons-comment-warning{
    font-size: 25px;
    animation: blinker 3s linear infinite;
  }

  @media (max-width: 768px) {
    .filter-elements {
      max-width: none;
    }
    p.package-desc{
        font-size: 14px;
      }
    
      .react-icons-comment-warning{
        font-size: 18px;
      }
  }

  @media (max-width: 768px) {
    .filter-elements {
        font-size: 12px;
      }

  }

  button.add-event-button {
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-family: IranSans !important;
    margin-top: 5%;
  }

  button.add-event-button-disabled {
    background-color: #8c8c8f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-family: IranSans !important;
    margin-top: 5%;
  }

  button.add-event-button:hover {
    background-color: #7d57f0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  div.package-desc-div{
    margin: auto;
    align-items: center;
    padding: 5px;
    flex: 1;
    border-radius: 5px;
    margin-bottom: 2%;
  }

  button.thanks-button-package{
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    /* margin-bottom: 15px; */
    font-family: IranSans;
  }

  button.thanks-button-package:hover{
    background-color: #7d57f0;
  }

  .timelines{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 30vh;
  }

  @media screen and (max-width: 1300px) {
    .Details {
        right: 100px;
        bottom: 100px;
        padding: 10px;
    }
  }

  @media screen and (max-width: 1000px) {
    .Details {
        right: 50px;
        bottom: 100px;
        padding: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    .Details {
        right: 50px;
        bottom: 100px;
        padding: 10px;
        width: 250px;
    }
    .Info{
        font-size: 14px;
    }
  }

  
  @media screen and (max-width: 768px) {
    .Details {
        right: 50px;
        bottom: 100px;
        padding: 10px;
        width: 300px;
    }
    .Info{
        font-size: 14px;
    }
  }

  @media screen and (max-width: 700px) {
    .Details {
        right: 50px;
        bottom: 100px;
        padding: 10px;
        width: 200px;
    }
    .Info{
        font-size: 12px;
    }
  }

  @media screen and (max-width: 575px) {
    .Details {
        right: 50px;
        bottom: 100px;
        padding: 10px;
        width: 150px;
    }
    .Info{
        font-size: 10px;
    }
  }

  @media screen and (max-width: 500px) {
    .Details {
        right: 50px;
        bottom: 320px;
        padding: 10px;
        width: 150px;
    }
    .Info{
        font-size: 10px;
    }
  }