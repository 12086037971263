div.all-pages-blog{
    margin: 0;
    padding: 0;
    margin-top: 140px;
    text-align: center;
    position: relative;
    background-image: url("../images/background-blog.png");
    background-size: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
}

