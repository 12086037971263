@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

.tour-details-wrapper {
  font-family: IranSans;
  line-height: 1.6;
  color: #333;
  text-align: right;
  width: 95%;
  margin: auto;
  margin-top: 100px;
}

.tour-content-wrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .tour-content-wrapper {
    flex-direction: column-reverse;
  }
}

.tour-reserve-form {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* position: sticky; */
  top: 20px;
}

.tour-details-content {
  flex: 2;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tour-image-gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tour-main-image {
  position: relative;
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #f9f9f9;
}

.tour-main-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transform-origin: center;
}

.tour-main-image img:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.tour-arrow-left,
.tour-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.5rem;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.tour-arrow-left:hover,
.tour-arrow-right:hover {
  color: #676565;
}

.tour-arrow-left {
  left: 15px;
}

.tour-arrow-right {
  right: 15px;
}

.tour-thumbnail-gallery {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  padding: 10px 5px;
  background: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.tour-thumbnail {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.3s ease, border 0.3s ease;
  border: 2px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tour-thumbnail:hover {
  transform: scale(1.1);
}

.tour-thumbnail.active {
  border: 2px solid #7620ff;
  transform: scale(1.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.tour-loading-message {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 50px;
}

.tour-error-message {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 50px;
  color: red;
}

.tour-details-wrapper {
  font-family: IranSans;
  color: #333;
}

.tour-organizer,
.tour-leader {
  display: flex;
  align-items: center;
  direction: ltr;
}

.tour-small-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.tour-enlarged-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.tour-enlarged-image-modal img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.tour-details-content {
  flex: 2;
  background: linear-gradient(to bottom right, #f9f9f9, #ffffff);
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
}
.tour-details-content:hover {
  transform: scale(1.01);
}

.tour-header-details h1 {
  font-size: 2rem;
  color: #3a3a3a;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.tour-detail-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  font-size: 1rem;
  line-height: 40px;
  color: #444;
  direction: rtl;
}

.tour-detail-row strong {
  font-weight: bold;
  color: #7620ff;
}

.tour-small-image {
  /* width: 50px;
  height: 50px; */
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.tour-small-image:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.tour-details-table {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.tour-organizer,
.tour-leader {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #555;
}

.tour-organizer img,
.tour-leader img {
  margin-right: 8px;
}

.tour-details-content {
  animation: fadeIn 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tour-reserve-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  direction: rtl;
  height: fit-content;
}

/* Autofill styling for Chrome, Edge, and other WebKit browsers */
.tour-reserve-input:-webkit-autofill {
  background-color: #fff !important; /* Match your input background color */
  color: #333 !important; /* Match your text color */
  border: 1px solid #ccc; /* Match your border color */
  box-shadow: 0 0 0px 1000px #fff inset !important; /* Ensure the background color applies */
  transition: background-color 5000s ease-in-out 0s; /* Prevent sudden flashes */
}

/* Autofill focus styling (optional) */
.tour-reserve-input:-webkit-autofill:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

/* For other browsers (Fallback) */
.tour-reserve-input:-moz-autofill {
  background-color: #fff !important;
  color: #333 !important;
}

.tour-detail-icon {
  margin-left: 8px; /* Add spacing between the icon and text */
  vertical-align: middle; /* Align the icon with the text */
}

.difficulty-value {
  position: relative;
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.difficulty-overlay {
  position: absolute;
  top: 70%;
  left: 10%; /* Position the overlay to the right */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: fit-content;
  z-index: 10;
  font-size: 14px;
}

.difficulty-item {
  display: flex;
  align-items: center;
}

.difficulty-item .difficulty-icon {
  margin-right: 8px;
  color: #007bff;
  font-size: 20px;
}

.difficulty-item p {
  margin: 0;
  color: #333;
  font-size: 10px;
  text-align: justify;
}

.tour-tabs-wrapper {
  margin-top: 40px;
  display: flex;
  gap: 15px;
  justify-content: flex-start; /* Align items to the left */
  flex-wrap: nowrap; /* Prevent wrapping to a new line */
  direction: rtl; /* Ensure correct text alignment for RTL languages */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Ensure items don't break lines */
  position: sticky; /* Keep it sticky at the top */
  top: 0; /* Stick to the top of the page */
  z-index: 1000; /* Ensure it stays on top of other elements */
  background: #fff; /* Background for clarity */
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for visual separation */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.tour-tabs-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, Edge */
}

.tour-tab {
  background: linear-gradient(135deg, #7f00ff, #e100ff);
  color: #fff;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background 0.3s ease;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0; /* Prevent tabs from shrinking */
}

.tour-tab:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #5e00cc, #ae00cc);
}

.tour-tab.active {
  background: linear-gradient(135deg, #5e00cc, #ae00cc);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.tour-tab-content {
  margin-top: 20px;
  background: #f9f9f9;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  direction: rtl;
  animation: fadeIn 0.5s ease-in-out;
}

.tour-condition-item {
  background: linear-gradient(135deg, #fdfbfb, #ebedee);
  border: 1px solid #e0e0e0;
  margin-bottom: 16px;
  padding: 16px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.8;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tour-condition-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: linear-gradient(135deg, #7f00ff, #e100ff);
  border-radius: 0 8px 8px 0;
}

.tour-condition-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.tour-condition-item span {
  font-weight: 600;
  color: #333;
}

.tour-condition-item .icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
  .tour-tab {
    /* width: 80%; */
    text-align: center;
  }

  .tour-tab-content {
    font-size: 0.9rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.all-tabs-content {
  margin-top: 20px;
  text-align: right;
  direction: rtl;
}

.tab-section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
}

.tab-section.active {
  border: 2px solid #7f00ff;
}

.tab-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #7f00ff;
  margin-bottom: 10px;
}

.tab-content {
  margin-top: 10px;
}

.participant-counter {
  margin: 12px 0;
}

.counter-controls {
  display: flex;
  align-items: center;
}

.counter-controls button {
  padding: 0 15px;
  font-size: 18px;
  border: 1px solid #ccc;
  background: #f7f7f7;
  cursor: pointer;
}

.counter-controls input {
  text-align: center;
  width: 60px;
  margin: 0 10px;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  max-width: 400px; /* Restrict max width for better layout */
  margin: 10px auto; /* Centered and responsive */
  z-index: 8000; /* Ensure dropdown stays on top */
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
  direction: rtl;
  font-size: 16px;
}

.selected-food {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.food-image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-left: 10px;
}

.food-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.food-name {
  font-weight: bold;
  font-size: 14px;
}

.food-price {
  font-size: 12px;
  color: gray;
}

.placeholder {
  color: gray;
  font-size: 14px;
}

/* Dropdown Container */
.dropdown-container {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  transition: all 0.3s ease-in-out;
}

/* Search Box */
.dropdown-search {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  text-align: right;
  margin-bottom: 5px;
}

/* Custom Scrollbar for Dropdown */
.dropdown-list {
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* Webkit (Chrome, Safari) */
.dropdown-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.dropdown-list::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #888, #555);
  border-radius: 10px;
  transition: background 0.3s ease-in-out;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #666, #444);
}

/* Dropdown Items */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.no-results {
  text-align: center;
  padding: 10px;
  color: gray;
  font-size: 14px;
}

/* RESPONSIVENESS */

/* Small screens (mobile) */
@media (max-width: 480px) {
  .custom-dropdown {
    max-width: 100%;
  }

  .dropdown-header {
    font-size: 14px;
    padding: 10px;
  }

  .food-image {
    width: 35px;
    height: 35px;
    margin-left: 8px;
  }

  .food-name {
    font-size: 13px;
  }

  .food-price {
    font-size: 11px;
  }

  .dropdown-search {
    font-size: 13px;
    padding: 8px;
  }

  .dropdown-item {
    padding: 10px;
  }

  .no-results {
    font-size: 12px;
  }
}

/* Medium screens (tablets) */
@media (max-width: 768px) {
  .custom-dropdown {
    max-width: 350px;
  }

  .dropdown-header {
    font-size: 15px;
  }

  .food-name {
    font-size: 14px;
  }

  .food-price {
    font-size: 12px;
  }

  .dropdown-search {
    font-size: 14px;
  }
}

/* Large screens (desktops) */
@media (min-width: 1024px) {
  .custom-dropdown {
    max-width: 400px;
  }

  .dropdown-header {
    font-size: 16px;
  }

  .food-name {
    font-size: 15px;
  }

  .food-price {
    font-size: 13px;
  }

  .dropdown-search {
    font-size: 15px;
  }
}

