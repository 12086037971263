/* .logo-shadow{
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
} */

.logo-shadow{
    width: 48px;
    height: auto;
}

@media (max-width: 480px) {
    .logo-shadow{
        width: 36px;
    }
}