@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

/* Test Result Page Styles */
.test-result-page {
  font-family: IranSans;
  direction: rtl;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 100px;
}

/* Header Styles */
.test-result-header {
  background: linear-gradient(90deg, #4e73df, #00796b);
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.test-result-main-title {
  font-size: 2.5em;
  margin: 0;
  font-weight: bold;
}

/* Content Area Styles */
.test-result-content {
  background-color: #ffffff;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  z-index: 1;
  position: relative;
}

/* Section Titles */
.test-result-section-title {
  font-size: 1.8em;
  color: #00796b;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #dee2e6;
  padding-bottom: 10px;
}

.test-result-section-title svg {
  margin-left: 10px;
  color: #00796b;
  font-size: 1.5em;
}

/* Subsection Titles */
.test-result-subsection-title {
  font-size: 1.5em;
  color: #00796b;
  margin-bottom: 15px;
  border-right: 4px solid #00796b;
  padding-right: 10px;
}

/* Parameter Cards Container */
.test-result-parameters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
}

/* Button Styles */
.test-result-upload-button {
  background-color: #00796b;
  color: #ffffff;
  border: none;
  padding: 14px 28px;
  font-size: 1.2em;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 30px;
  display: block;
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.test-result-upload-button:hover {
  background-color: #17a673;
  transform: scale(1.05);
}

/* No Results Section */
.test-result-no-result {
  text-align: center;
  padding: 60px;
}

.test-result-no-result p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

/* Parameter Card Styles */
.test-result-parameter-card {
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border: 1px solid #e4e8f0;
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.test-result-parameter-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Decorative Left Border */
.test-result-parameter-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  background: linear-gradient(180deg, #00796b, #36b9cc);
  border-radius: 12px 0 0 12px;
}

/* Parameter Title */
.test-result-parameter-title {
  font-size: 1.5em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Parameter Category */
.test-result-parameter-category {
  font-size: 1em;
  color: #888;
  font-style: italic;
  margin-bottom: 15px;
}

/* Parameter Description */
.test-result-parameter-description {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Parameter Result */
.test-result-parameter-result {
  font-size: 1.2em;
  color: #495057;
  font-weight: bold;
  margin-bottom: 15px;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
}

/* Parameter Ranges */
.test-result-parameter-range {
  font-size: 1em;
  color: #495057;
  background: #f7f9fc;
  border: 1px solid #e4e8f0;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* Long Description */
.test-result-parameter-long-description {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
  line-height: 1.8;
  padding: 10px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #e4e8f0;
}

/* Decorative Icon */
.test-result-parameter-card::after {
  content: "\f0c3"; /* FontAwesome flask icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 80px;
  color: rgba(36, 164, 155, 0.1); /* Semi-transparent background icon */
  pointer-events: none;
}

/* Hover Effects */
.test-result-parameter-card:hover .test-result-parameter-title {
  color: #00796b;
}

.test-result-parameter-card:hover .test-result-parameter-result {
  color: #00796b;
}


/* Utility Classes */
.text-success {
  color: #00796b !important;
}

.text-warning {
  color: #f6c23e !important;
}

.text-danger {
  color: #e74a3b !important;
}

.text-info {
  color: #36b9cc !important;
}

/* Styling for the List Container */
.test-result-item-list {
  list-style: none; /* Removes default bullet points */
  padding: 0;
  margin: 20px 0;
  border-left: 4px solid #00796b; /* Adds a colored line to the left */
  padding-left: 20px;
}

/* Styling for Each List Item */
.test-result-item {
  position: relative;
  padding: 10px 20px;
  margin-bottom: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.3s ease;
  font-size: 1em;
  color: #333;
  text-align: justify;
}

/* Bullet Point Animation */
.test-result-item-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #00796b;
  border-radius: 50%;
  margin-left: 10px;
  vertical-align: middle;
  transition: transform 0.3s ease;
}

/* Hover Effects for List Items */
.test-result-item:hover {
  transform: translateX(10px); /* Moves the item slightly to the right */
  background-color: #e6f4ea; /* Adds a subtle background color */
}

/* Hover Effects for Bullet Point */
.test-result-item:hover .test-result-item-bullet {
  transform: scale(1.2); /* Enlarges the bullet point */
}

/* Text Animation */
.test-result-item span {
  display: inline-block;
  transition: color 0.3s ease;
}

.test-result-item:hover span {
  color: #00796b; /* Changes the text color on hover */
}

/* Add Transition for Smooth Appearance */
.test-result-item-list .test-result-item {
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeIn 0.5s ease forwards;
}

.test-result-item-list .test-result-item:nth-child(1) {
  animation-delay: 0.1s;
}

.test-result-item-list .test-result-item:nth-child(2) {
  animation-delay: 0.2s;
}

.test-result-item-list .test-result-item:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .test-result-parameter-card {
    padding: 15px;
  }

  .test-result-parameter-title {
    font-size: 1.2em;
  }

  .test-result-parameter-result {
    font-size: 1em;
  }
}

/* Responsiveness */
@media (max-width: 768px) {
  .test-result-parameter-card {
    flex: 1 1 100%;
  }

  .test-result-main-title {
    font-size: 1.5em;
  }

  .test-result-section-title {
    font-size: 1.2em;
  }

  .test-result-subsection-title {
    font-size: 1em;
  }

  .test-result-parameter-title {
    font-size: 1.1em;
  }

  .test-result-item{
    font-size: 0.8em;
  }

  .test-result-parameter-description,
  .test-result-parameter-result,
  .test-result-parameter-range,
  .test-result-parameter-long-description {
    font-size: 0.85em;
  }
}

.test-result-parameters-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.test-result-parameters-table th,
.test-result-parameters-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.test-result-parameters-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.test-result-section-title {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.test-result-text {
  margin-top: 10px;
  line-height: 1.6;
  font-size: 1em;
}
