.title-blog{
    margin-top: 80px;

}

.title-blog-desktop{
    margin-top: 80px;
}

.title-blog-mobile{
    margin-top: 84px;
}