.Cogmo-Score-GameScoreboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #fbc2eb, #a6c1ee);
    color: #333;
    font-family: IranSans, sans-serif;
    padding: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  
  .Cogmo-Score-GameScoreboard-header {
    text-align: center;
    margin-bottom: 30px;
    color: white;
    background: linear-gradient(90deg, #ff758c, #ff7eb3);
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .Cogmo-Score-GameScoreboard-user-stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .Cogmo-Score-GameScoreboard-user-score, .GameScoreboard-user-rank {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 0 10px;
  }
  
  .Cogmo-Score-GameScoreboard-user-score h2, .GameScoreboard-user-rank h2 {
    color: #ff758c;
  }
  
  .Cogmo-Score-GameScoreboard-chart {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .Cogmo-Score-GameScoreboard-chart h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #444;
  }
/* Container Styling */
.Cogmo-Score-scoreboard-container {
  text-align: center;
  padding: 20px;
  max-width: 85%;
  margin: 0 auto;
  margin-top: 100px;
  background: linear-gradient(to right, #fbc2eb, #a6c1ee);
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  font-family: IranSans, sans-serif;
  direction: rtl;
}

/* User Info Table */
.Cogmo-Score-user-info {
  margin-bottom: 20px;
}

.Cogmo-Score-user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
}

.Cogmo-Score-user-table th,
.Cogmo-Score-user-table td {
  border: 1px solid #ddd;
  padding: 10px 15px;
}

.Cogmo-Score-user-table th {
  background: #ff758c;
  color: white;
}

.Cogmo-Score-user-table td {
  background: #fff;
  color: #333;
}

/* Chart Slider */
.Cogmo-Score-chart-slider {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
}

.Cogmo-Score-chart-container {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  direction: rtl;
}


.slick-dots li button:before {
  color: #444;
}

.slick-dots li.slick-active button:before {
  color: #ff758c;
}

/* Chart Header */
.Cogmo-Score-chart-container h3 {
  font-size: 0.9em;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Slider Arrows */
.Cogmo-Score-slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #ff758c;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.Cogmo-Score-slider-arrow.prev {
  right: -50px;
}
.Cogmo-Score-title {
  direction: rtl;
  font-size: 1.3em;
  color: darkslateblue;
  font-weight: bold;
}
.Cogmo-Score-slider-arrow.next {
  left: -50px;
}

.Cogmo-Score-slider-arrow span {
  font-size: 20px;
  font-weight: bold;
}

.Cogmo-Score-chart-navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.Cogmo-Score-nav-button {
  background: #ff758c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.Cogmo-Score-nav-button:hover {
  transform: scale(1.1);
}

.Cogmo-Score-nav-button:focus {
  outline: none;
}

.Cogmo-Score-nav-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .Cogmo-Score-chart-container {
    padding: 10px;
  }

  .Cogmo-Score-user-table td,
  .Cogmo-Score-user-table th {
    font-size: 12px;
  }

  .Cogmo-Score-slider-arrow {
    width: 25px;
    height: 25px;
  }
}