@font-face {
    font-family: IranSans;
    src: url("../fonts/IranSans.ttf");
  }

.success-container {
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom right, #e0f7fa, #80deea); /* Light blue gradient */
  }
  
  .success-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 30px 40px;
    text-align: center;
    width: fit-content;
    animation: fadeIn 1s ease-in-out;
  }
  
  .success-title {
    font-family: IranSans;
    font-size: 26px;
    margin-bottom: 15px;
    color: #28a745; /* Green for success */
  }
  
  .success-message {
    font-family: IranSans;
    font-size: 18px;
    color: #333; /* Dark gray */
    margin-bottom: 20px;
  }
  
  .success-button {
    font-family: IranSans;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #007bff; /* Blue for button */
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .success-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .failure .success-title {
    color: #d32f2f; /* Red for failure */
  }
  
  .failure .success-button {
    background-color: #d32f2f; /* Red for button */
  }
  
  .failure .success-button:hover {
    background-color: #b71c1c; /* Darker red on hover */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  