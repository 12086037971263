.modal-add-event-con {
    font-family: IranSans;
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20000; /* Ensure it's above other overlays */
}

.modal-main-add-event-con {
    position: relative;
    background-color: white;
    height: auto;
    border-radius: 25px;
    width: 30%;
    z-index: 20001; /* Higher than overlay but contained within it */
    font-family: IranSans;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-main-add-event-con {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 768px) {
    section.modal-main-add-event-con {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    section.modal-main-add-event-con {
      width: 95%;
    }
  }

  @media screen and (max-width: 600px) {
    .modal-main-add-event-con {
      width: 100%;
      max-width: 90%;
    }
  }

.confetti-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200702 !important; /* Ensure it's above modal contents */
}

[class^="confetti-explosion-screen"] {
    z-index: 200702 !important;
}

.success-message-sign {
    direction: rtl;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    color: #333;
  }
  
  .succ-sign-p {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .succ-sign-start {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .login-popup-button {
    background-color: #DE3163;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-popup-button:hover {
    background-color: #c02952;
  }
  