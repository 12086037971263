@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

/* Container */
.payment-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    #e0f7fa,
    #80deea,
    #4dd0e1
  ); /* Vibrant gradient */
  padding: 20px;
  direction: rtl;
}

/* Card */
.payment-page-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Deeper shadow for emphasis */
  padding: 30px 40px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  width: 100%;
  max-width: 500px;
  border: 1px solid #e0f7fa; /* Subtle border */
}

/* Title */
.payment-page-title {
  font-family: IranSans;
  font-size: 28px; /* Larger title for emphasis */
  margin-bottom: 20px;
  color: #28a745;
  text-shadow: 1px 1px 2px rgba(40, 167, 69, 0.4); /* Text shadow for glow effect */
}

/* Icons */
.payment-page-icon {
  margin-right: 10px;
  color: #007bff;
  font-size: 20px; /* Larger icons */
}

.payment-page-icon-title {
  margin-right: 10px;
  color: #28a745;
  font-size: 26px;
}

/* Options */
.payment-page-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.payment-page-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Labels */
.payment-page-label {
  font-family: IranSans;
  font-size: 16px;
  margin-bottom: 8px;
  color: #555;
  font-weight: bold; /* Emphasize labels */
}

/* Inputs */
.payment-page-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.3s;
  background: #f8f9fa; /* Slight background color */
}

.payment-page-input:focus {
  border-color: #6e8efb;
  outline: none;
  box-shadow: 0 0 12px rgba(110, 142, 251, 0.7); /* More focus glow */
}

/* Button */
.payment-page-button {
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient button */
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.4); /* Button shadow */
  margin-left: 2%;
}

.payment-page-button:hover {
  background: linear-gradient(135deg, #0056b3, #004085); /* Darker gradient */
  transform: scale(1.05); /* Slight scale effect */
  box-shadow: 0 8px 20px rgba(0, 123, 255, 0.6); /* Enhanced shadow on hover */
}

/* Loading State */
.payment-page-loading {
  cursor: not-allowed;
  background-color: #ccc;
  box-shadow: none; /* No shadow when loading */
}

/* Description Section */
.payment-page-description {
  text-align: right;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  background: #f8f9fa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.payment-page-description h3 {
  font-family: IranSans;
  font-size: 18px;
  color: #007bff;
  margin-bottom: 10px;
}

.payment-page-description p {
  font-family: IranSans;
  font-size: 14px;
  color: #333;
  line-height: 1.8; /* More spacing for readability */
  text-align: justify;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .payment-page-card {
    padding: 20px;
  }

  .payment-page-title {
    font-size: 22px;
  }

  .payment-page-input {
    font-size: 14px;
    padding: 10px;
  }

  .payment-page-button {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .payment-page-card {
    padding: 15px;
  }

  .payment-page-title {
    font-size: 20px;
  }

  .payment-page-input {
    font-size: 14px;
    padding: 8px;
  }

  .payment-page-button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.payment-page-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.payment-page-back-button {
  background-color: #6c757d; /* Grey for back button */
}

.payment-page-back-button:hover {
  background-color: #5a6268; /* Darker grey */
}
