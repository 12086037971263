@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

.reminder-list {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
  max-height: 250px; /* Set the height for the scroll area */
  overflow-y: auto; /* Enable vertical scrolling */
}

.reminder-list-h3 {
  margin: auto;
  margin-bottom: 8px;
  text-align: center;
}

.reminder-list-p {
  margin: auto;
  text-align: center;
}

.reminder-item {
  padding: 15px;
  margin-bottom: 10px; /* Add spacing between items */
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f8f8; /* Light background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Animation */
}

.reminder-item:hover {
  transform: translateY(-2px); /* Lift on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow */
}

.reminder-item:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last item */
}

.reminder-item p {
  margin: 0;
  font-size: 12px;
  color: #555; /* Softer text color for readability */
  line-height: 1.5; /* Improved readability */
  padding: 4px; /* Add vertical padding */
  display: grid;
  grid-template-columns: 200px 200px; /* Fixed width for keys, flexible for values */
  align-items: center; /* Align content vertically */
  text-align: right;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  width: 100%; /* Set a width or max-width as necessary */
}

.reminder-item p:hover {
  color: #333; /* Darken text color on hover */
  background-color: rgba(217, 83, 79, 0.1); /* Light highlight on hover */
  border-radius: 4px; /* Round corners for the highlight */
  padding: 6px; /* Slight indent on hover */
}

.delete-button-reminder {
  background-color: #d9534f;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.delete-button-reminder:hover {
  background-color: #c9302c;
  transform: scale(1.05); /* Slight enlargement on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .reminder-item {
    padding: 12px;
    flex-direction: column;
    align-items: center;
  }

  .reminder-item p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .reminder-item button {
    padding: 6px 10px;
    font-size: 12px;
    margin-top: 5px; /* Add spacing between button and text */
  }
}

@media (max-width: 550px) {
  .reminder-item {
    padding: 10px;
  }

  .reminder-item p {
    font-size: 12px;
    grid-template-columns: 150px 100px;
  }

  .reminder-item button {
    padding: 5px 8px;
    font-size: 10px;
  }
}

@media (max-width: 380px) {
  .reminder-item p {
    font-size: 10px;
    grid-template-columns: 125px 100px;
  }
}

.reminder-list-button-div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Add Reminder button */
.add-reminder-button {
  background-color: #00796b;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto;
  width: 200px;
  text-align: center;
}

.add-reminder-button:hover {
  background-color: #0aa08f;
}

/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 60px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.reminder-form input,
.reminder-form select {
  margin-bottom: 15px;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.reminder-form button {
  background-color: #5cb85c;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.reminder-form button:hover {
  background-color: #4cae4c;
}

.profile-container {
  direction: rtl;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  /* height: 100vh; */
  font-family: IranSans;
  gap: 16px;
  padding: 20px;
  background-color: #f5f8fa; /* Light background to match the website */
  width: 90%;
  margin: auto;
  border-radius: 16px; /* Rounded container edges for a softer look */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for a modern effect */
  margin-top: 100px;
  margin-bottom: 50px;
}

.box {
  padding: 24px;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: "IRANSans", sans-serif;
  color: #4a4a4a;
  position: relative;
  max-width: 90vw;
}

.box-title {
  font-size: 20px;
  font-weight: 700;
  color: #00796b; /* Same color as your website theme */
  margin-bottom: 16px;
  padding: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #004d40; /* Subtle bottom border */
  text-transform: uppercase;
  letter-spacing: 1px; /* Spacing for modern look */
  display: inline-block;
  position: relative;
}

.box:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

/* Adjust the layout for all the boxes */
.box:nth-child(1) {
  grid-row-start: 1;
  grid-row-end: 7;
}

.box:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.box:nth-child(3) {
  grid-row-start: 3;
  grid-row-end: 5;
}

.box:nth-child(4) {
  grid-row-start: 5;
  grid-row-end: 7;
}

/* Update these styles for the third column to have three boxes */
.box:nth-child(5) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.box:nth-child(6) {
  grid-row-start: 3;
  grid-row-end: 5;
}

.box:nth-child(7) {
  grid-row-start: 5;
  grid-row-end: 7;
}

@media (max-width: 1000px) {
  .profile-container {
    direction: rtl;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    height: 100vh;
    gap: 16px; /* Adjust the gap as needed */
    padding: 20px;
    background-color: #f8f8f8; /* Background color similar to the image */
    width: 90%;
    margin: auto;
  }

  .box-title {
    font-size: 16px;
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  .box {
    padding: 0px;
    background-color: #feefe8;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .box:nth-child(1),
  .box:nth-child(2),
  .box:nth-child(3),
  .box:nth-child(4),
  .box:nth-child(5),
  .box:nth-child(6),
  .box:nth-child(7) {
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: 1;
    grid-column-end: 2; /* Make sure all boxes stack on one column */
  }

  .profile-image {
    border: 3px solid white;
    width: 200px;
    height: auto;
    border-radius: 100%;
    margin: auto;
    text-align: center;
  }

  .profile-name {
    margin-top: 5%;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: var(--مشکی-متن);
  }

  .profile-number {
    margin-top: 1%;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: rgb(138, 9, 46);
  }

  .user-details-box {
    background-color: #fed9c9;
    padding: 20px;
    text-align: right;
    border-radius: 25px;
    margin-top: 20%;
  }

  @media (max-width: 1000px) {
    .profile-container {
      grid-template-columns: 1fr;
      height: auto;
      margin-top: 100px;
      padding: 0;
    }

    .box:nth-child(1),
    .box:nth-child(2),
    .box:nth-child(3),
    .box:nth-child(4),
    .box:nth-child(5),
    .box:nth-child(6) {
      grid-row-start: auto;
      grid-row-end: auto;
    }
  }
}

@media (max-width: 480px) {
  .box-title {
    font-size: 14px;
  }
}

/* Typography and Image Styling */
.profile-image {
  border: 3px solid white;
  width: 200px;
  height: 200px; /* Ensures the image is a perfect square */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  object-position: center; /* Centers the image within the circle */
  display: block;
  margin: auto;
}

.profile-image:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Hover effect for profile image */
}

.profile-name {
  margin-top: 15px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #333;
}

.profile-number {
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #00796b; /* Matches the primary color of your website */
}

.user-details-box {
  background-color: #ffe0b2;
  padding: 24px;
  text-align: right;
  border-radius: 16px;
  margin-top: 20%;
  color: #333;
}

/* Button Styling */
.chatbox__send--footer {
  background-color: #22baa9;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.chatbox__send--footer:hover {
  background-color: #004d40;
}

.navbar-div-profile {
  direction: ltr;
}

.chatbot-container {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.chatbot-messages {
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 12px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
}

.chatbot-input {
  width: 80%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
}

.chatbot-button {
  background-color: #00796b;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.chatbot-button:hover {
  background-color: #004d40;
}

/* For the chatbot messages area */
.chatbot-messages::-webkit-scrollbar {
  width: 6px;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background-color: #00796b;
  border-radius: 3px;
}

.chatbox__support {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: fit-content;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatbox__header {
  padding: 16px;
  background-color: #00796b;
  color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.chatbox__heading--header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}

.chatbox__description {
  font-size: 14px;
  margin-bottom: 0;
}

.chatbox__messages {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
  background-color: #f1f1f1;
}

.messages__item {
  padding: 8px 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: inline-block;
}

.messages__item--visitor {
  background-color: #e0f7fa;
  align-self: flex-start;
}

.messages__item--operator {
  background-color: #00796b;
  color: white;
  align-self: flex-end;
}

.chatbox__footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chatbox__input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 14px;
  outline: none;
  margin-left: 10px;
}

.chatbox__send--footer {
  background-color: #00796b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: not-allowed;
}

.chatbox__send--footer:hover {
  background-color: #004d40;
}

/* For the chatbot messages area */
.chatbox__messages::-webkit-scrollbar {
  width: 6px;
}

.chatbox__messages::-webkit-scrollbar-thumb {
  background-color: #00796b;
  border-radius: 3px;
}

.coming-soon-message {
  text-align: center;
  padding: 30px;
  font-size: 18px;
  animation: fadeIn 1.5s ease-in-out, pulse 2s infinite;
  background-color: #ffefc5;
  border-radius: 10px;
}

.coming-soon-message h4 {
  font-size: 22px;
  color: #ff9900;
}

.coming-soon-message p {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.coming-soon-chat {
  text-align: right;
}

.profile-info {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.info-item:last-child {
  border-bottom: none;
}

.label-prof {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  width: 50%;
  text-align: right;
}

.save-button,
.edit-button,
.cancel-button {
  background-color: #00796b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button:hover,
.edit-button:hover {
  background-color: #0aa08f;
}

.span-prof {
  font-size: 16px;
  color: #555;
}

.profile-info .info-item:hover {
  background-color: #e8f0fe;
}

.profile-info .info-item span {
  text-align: right;
  flex-grow: 1;
  margin-left: 20px;
}

.file-preview {
  margin: 15px;
}

.dropzone {
  margin-bottom: 10%;
}

.upload-button-home {
  border-radius: 20px;
  padding: 9px 16px 9px 12px;
  cursor: pointer;
  border: none;
  text-align: center;
  color: #333;
  display: flex;
  text-align: center;
  margin: auto;
}

.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.dropzone {
  width: 100%;
  max-width: 500px;
  min-height: 150px;
  border: 2px dashed #00796b;
  border-radius: 12px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  margin: auto;
  margin-bottom: 5%;
  margin-top: 5%;
}

.dropzone:hover {
  border-color: #0056b3;
  background-color: #eef7ff;
}

.drag-drop-file-text {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
  transition: color 0.3s ease;
}

.manual-upload {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.upload-button-home {
  background-color: #00796b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.upload-button-home:hover {
  background-color: #0aa08f;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.is-drag-active {
  border-color: #4caf50;
  background-color: #e6ffe6;
}

.is-drag-active .drag-drop-file-text {
  color: #4caf50;
}

.upload-box {
  margin: auto !important;
  text-align: center !important;
  align-items: center !important;
}

.reminder-box {
  background: linear-gradient(135deg, #fff8e1 0%, #ffffff 100%);
  border: 2px dashed #ffca28;
}

.reminder-content {
  padding: 20px;
  text-align: center;
}

.reminder-description {
  font-size: 16px;
  color: #757575;
  margin: 10px 0;
  text-align: justify;
}

.coming-soon-reminder {
  font-size: 14px;
  color: #ff7043;
  font-weight: 500;
  margin-top: 10px;
}

.remaining-tests {
  margin-top: 2%;
  font-family: IranSans;
}

.test-info {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.test-count {
  font-weight: 500;
  color: #444;
}

.test-count-number {
  font-weight: 700;
  color: #28a745; /* Green for remaining tests */
}

.test-total-number {
  font-weight: 700;
  color: #6c757d; /* Gray for the total count */
}

.last-test-date {
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.last-test-date i {
  margin-left: 10px;
  color: #007bff; /* Blue calendar icon */
}

.test-date {
  font-weight: 700;
  color: #ff5722; /* Orange for the test date */
}

.test-message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-message i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.test-full {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.test-available {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.test-unavailable {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.edit-button.disabled {
  background: #959496;
  color: white;
  border: 1px solid #dfdede;
  color: white;
  font-family: IranSans;
  text-align: center;
  cursor: not-allowed;
}

.form-group {
  margin-bottom: 20px;
}

.label-prof {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
}

.input-field:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

.save-button,
.edit-button,
.cancel-button {
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.success-message,
.error-message {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.info-item {
  margin-bottom: 10px;
}

.span-prof {
  font-size: 16px;
  color: #555;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.cancel-button {
  background-color: #6c757d;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.profile-image-container {
  position: relative;
  display: inline-block;
  margin: auto;
  text-align: center;
}

.camera-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.camera-icon img {
  width: 24px;
  height: 24px;
}

.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fontawesome-icon-image:hover {
  color: rgb(197, 192, 192);
}

.message {
  margin-top: 10px;
  font-size: 14px;
}

.message-success {
  color: #155724;
}

.message-error {
  color: #721c24;
}

/* Full-screen loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* High z-index to ensure it covers the entire screen */
}

/* Loading spinner */
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* General Responsive Text */
.drag-drop-file-text {
  font-size: 1rem; /* Using rem for relative sizing */
}

.upload-button-home {
  font-size: 1rem; /* Responsive font size */
}

/* Test Info Styling */
.test-count {
  font-size: 1rem; /* Adjusted for responsiveness */
}

.test-info {
  font-size: 1.2rem; /* Slightly larger text */
}

/* Last Test Date */
.last-test-date {
  font-size: 0.9rem; /* Adjust for better fit on smaller screens */
}

.last-test-date i {
  font-size: 1.2rem; /* Icon size */
}

/* Test Message Styling */
.test-message {
  font-size: 1rem; /* Adjusted for responsiveness */
}

.test-message i {
  font-size: 1.5rem; /* Icon size */
}

/* Responsive Adjustments Using Media Queries */
@media (max-width: 768px) {
  .drag-drop-file-text,
  .test-count,
  .test-info,
  .last-test-date,
  .test-message {
    font-size: 0.9rem; /* Smaller text for tablets and small devices */
  }

  .upload-button-home {
    font-size: 0.9rem; /* Adjust button font size */
  }
}

@media (max-width: 480px) {
  .drag-drop-file-text,
  .test-count,
  .test-info,
  .last-test-date,
  .test-message {
    font-size: 0.8rem; /* Even smaller text for phones */
  }

  .upload-button-home {
    font-size: 0.8rem; /* Adjust button font size for phones */
  }
}

/* General Styling for Coming Soon Message */
.coming-soon-message {
  padding: 2rem; /* Using rem for consistent scaling */
  font-size: 1.125rem; /* 18px equivalent in rem */
  margin: 1rem auto; /* Centered with margin */
}

.coming-soon-message h4 {
  font-size: 1.375rem; /* 22px equivalent */
  margin-bottom: 0.5rem; /* Space below the heading */
}

.coming-soon-message p {
  font-size: 0.875rem; /* 14px equivalent */
  margin: 0.5rem 0;
}

.coming-soon-chat {
  font-size: 0.875rem; /* 14px equivalent */
  margin-top: 0.5rem;
}

/* Reminder Box Styling */
.reminder-box {
  margin: 1rem auto;
}

.reminder-content {
  padding: 1.25rem; /* 20px equivalent */
}

.reminder-description {
  font-size: 1rem; /* 16px equivalent */
  margin: 0.5rem 0;
}

/* Chatbox Footer */
.chatbox__footer {
  padding: 1rem;
}

.chatbox__input {
  padding: 0.75rem; /* 12px equivalent */
  font-size: 0.875rem; /* 14px equivalent */
  margin-right: 0.5rem;
}

.chatbox__send--footer {
  padding: 0.5rem 1rem; /* Button padding */
  font-size: 0.875rem; /* 14px equivalent */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .coming-soon-message {
    padding: 1.5rem;
    font-size: 1rem; /* 16px */
  }
  .coming-soon-message h4 {
    font-size: 1.25rem; /* 20px */
  }
  .coming-soon-message p {
    font-size: 0.8125rem; /* 13px */
  }
  .reminder-content {
    padding: 1rem;
  }
  .reminder-description {
    font-size: 0.875rem; /* 14px */
  }
  .chatbox__input {
    font-size: 0.8125rem; /* 13px */
  }
  .chatbox__send--footer {
    font-size: 0.8125rem; /* 13px */
  }
}

@media (max-width: 480px) {
  .coming-soon-message {
    padding: 1rem;
    font-size: 0.9375rem; /* 15px */
  }
  .coming-soon-message h4 {
    font-size: 1.125rem; /* 18px */
  }
  .coming-soon-message p {
    font-size: 0.75rem; /* 12px */
  }
  .reminder-content {
    padding: 0.75rem;
  }
  .reminder-description {
    font-size: 0.8125rem; /* 13px */
  }
  .chatbox__input {
    font-size: 0.75rem; /* 12px */
  }
  .chatbox__send--footer {
    font-size: 0.75rem; /* 12px */
  }
}

/* Form Group Styling */
.form-group {
  margin-bottom: 1.25rem; /* 20px */
}

.input-field {
  padding: 0.625rem; /* 10px */
  font-size: 1rem; /* 16px */
  margin-top: 0.3125rem; /* 5px */
  box-sizing: border-box; /* To include padding in width */
}

/* Button Styling */
.save-button,
.edit-button,
.cancel-button {
  font-size: 1rem; /* 16px */
}

/* Profile Info Styling */
.profile-info {
  max-width: 25rem; /* 400px */
  margin: 0 auto;
  padding: 1.25rem; /* 20px */
}

.info-item {
  padding: 0.625rem 0; /* 10px */
  margin-bottom: 0.625rem; /* 10px */
}

.label-prof {
  font-size: 1rem; /* 16px */
  flex-basis: 50%; /* Use flexbox for better alignment */
}

.span-prof {
  font-size: 1rem; /* 16px */
  flex-basis: 50%; /* Use flexbox for better alignment */
}

/* Button Group Styling */
.button-group {
  margin-top: 1rem; /* 16px */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .input-field {
    font-size: 0.875rem; /* 14px */
    padding: 0.5rem; /* 8px */
  }

  .save-button,
  .edit-button,
  .cancel-button {
    font-size: 0.875rem; /* 14px */
  }

  .profile-info {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .label-prof,
  .span-prof {
    font-size: 0.875rem; /* 14px */
  }
}

@media (max-width: 480px) {
  .input-field {
    font-size: 0.8125rem; /* 13px */
    padding: 0.375rem; /* 6px */
  }

  .save-button,
  .edit-button,
  .cancel-button {
    font-size: 0.8125rem; /* 13px */
  }

  .profile-info {
    padding: 0.75rem; /* Further reduce padding for small screens */
  }

  .label-prof,
  .span-prof {
    font-size: 0.8125rem; /* 13px */
  }
}

/* Profile Image Container */
.profile-image-container {
  overflow: hidden; /* Hide overflow for a circular effect */
  margin: 0 auto; /* Center the image container */
}

.fontawesome-icon-image {
  font-size: 2rem; /* Adjust size of the camera icon */
}

/* Buttons */
.save-button,
.remove-button {
  font-size: 1rem;
}

/* Messages */
.message {
  font-size: 0.875rem; /* 14px */
}

/* Profile Name and Number */
.profile-name {
  font-size: 1.375rem; /* 22px */
}

.profile-number {
  font-size: 1rem; /* 16px */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .fontawesome-icon-image {
    font-size: 1.5rem; /* Adjust icon size */
  }

  .save-button,
  .remove-button {
    font-size: 0.875rem; /* 14px */
    padding: 0.375rem 0.75rem;
  }

  .profile-name {
    font-size: 1.125rem; /* 18px */
  }

  .profile-number {
    font-size: 0.875rem; /* 14px */
  }
}

@media (max-width: 480px) {
  .fontawesome-icon-image {
    font-size: 1.25rem; /* Further reduce icon size */
  }

  .save-button,
  .remove-button {
    font-size: 0.75rem; /* 12px */
    padding: 0.25rem 0.5rem;
  }

  .profile-name {
    font-size: 1rem; /* 16px */
  }

  .profile-number {
    font-size: 0.8125rem; /* 13px */
  }
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-height: 90vh; /* Set max height to fit within the viewport */
  overflow-y: auto; /* Enable scrolling if content exceeds max height */
  animation: fadeIn 0.3s;
}

.modal-content.choosing {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: fit-content;
  max-height: 90vh; /* Set max height to fit within the viewport */
  overflow-y: auto; /* Enable scrolling if content exceeds max height */
  animation: fadeIn 0.3s;
}

/* Close button */
.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* General Styles for Form */
.medication-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between form groups */
  justify-content: space-between;
  margin: 10px 0;
}

.medication-form .form-group {
  flex: 1 1 25%;
  min-width: 200px; /* Increase minimum width for better usability */
}

.medication-form label {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
  display: block;
  color: #333; /* Darker color for readability */
}

.medication-form input[type="text"],
.medication-form input[type="number"],
.DatePicker,
.medication-form input[type="datetime-local"],
.medication-form select,
.medication-form textarea {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 12px;
  transition: border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.submit-reminder {
  width: max-content;
}

.medication-form input:focus,
.medication-form select:focus,
.DatePicker__input:focus,
.medication-form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Blue glow on focus */
}

.DatePicker__input {
  border: none !important;
}

.medication-form textarea {
  resize: vertical;
  height: 80px; /* Increase default height */
}

.medication-form .error {
  color: #e74c3c; /* Bright red for errors */
  font-size: 14px;
  margin-top: 4px;
}

/* Modal Styles */
.medication-form .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Darker background for more focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.medication-form .modal-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideIn 0.4s ease;
}

/* Close Button */
.medication-form .modal-close {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.medication-form .modal-close:hover {
  color: #007bff;
}

.submit-reminder {
  background-color: #00796b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-reminder:hover {
  background-color: #0aa08f;
}

.Calendar__monthYear button {
  color: black !important;
  font-size: 10px !important;
}

.Calendar__monthSelectorItem button {
  color: black !important;
  font-size: 10px !important;
}

.Calendar__monthSelectorItemText {
  color: black !important;
  font-size: 10px !important;
}

.Calendar__yearSelectorText {
  color: black !important;
  font-size: 10px !important;
}

.Calendar__monthArrow {
  font-size: 10px !important;
}

.DatePicker__calendarContainer {
  height: 50px !important;
}

.date-picker-calendar {
  z-index: 100 !important;
  font-size: 8px !important;
}

.date-picker-wrapper {
  z-index: 101 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .medication-form .form-group {
    flex: 1 1 80%; /* Full width on smaller screens */
  }
  .medication-form .modal-content {
    width: 95%;
    padding: 20px;
  }
  .medication-form input,
  .DatePicker,
  .medication-form select,
  .medication-form textarea,
  .submit-reminder {
    font-size: 12px; /* Smaller text size for better fit */
  }

  .medication-form label {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 12px;
    display: block;
    color: #333; /* Darker color for readability */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .medication-form .form-group {
    flex: 1 1 48%; /* Two items per row on medium screens */
  }
}

/* Modal Animation */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.medication-form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../images/feature.png") no-repeat 5% 50%;
  background-size: 15px 15px;
  cursor: pointer;
  font-family: IranSans !important;
}

.MuiFormControl-root {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd !important;
  font-size: 12px;
  transition: border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  margin-top: 5px !important;
  text-align: center !important;
  direction: ltr !important;
}

.MuiInputBase-input {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: none !important;
  text-align: center !important;
}
.MuiOutlinedInput-notchedOutline {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: none !important;
  text-align: center !important;
}

.reminder-list::-webkit-scrollbar {
  width: 10px;
  border-radius: 26px;
}

.reminder-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 26px;
  margin-top: 25px;
  margin-bottom: 25px;
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #f7f7f7;
}

.reminder-list::-webkit-scrollbar-thumb {
  border-radius: 26px;
  background: #b9b8b8;
  margin-top: 25px;
  margin-bottom: 25px;
  /* outline: 1px solid slategrey; */
}

.edit-icon-reminder {
  background-color: #00796b;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.edit-icon-reminder:hover {
  background-color: #0aa08f;
}

.result-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Modal overlay background */
.chest-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.chest-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Modal image */
.chest-modal-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

/* Close button */
.chest-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.chest-close-button:hover {
  color: #f00;
}

/* Cursor pointer for the result image */
.chest-result-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
}

/* Payment Button Styling */
.payment-button {
  font-family: IranSans;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #007bff; /* Blue */
  color: white;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.payment-button.loading {
  background-color: #6c757d; /* Gray for loading */
  cursor: not-allowed;
  opacity: 0.8;
}

.payment-button:hover:not(.loading) {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Spinner for Loading */
.spinner {
  border: 3px solid #f3f3f3; /* Light gray */
  border-top: 3px solid #007bff; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.group-payment-btn {
  padding: 6px 15px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background: linear-gradient(135deg, #00796b, #05a995);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.group-payment-btn:hover {
  background: linear-gradient(135deg, #03a18f, #0acdb6);
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(3, 144, 83, 0.6);
}

.recipient-selection-container {
  text-align: center;
  padding: 20px;
}

.recipient-selection-container h2 {
  margin: auto;
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
  color: #444;
}

.recipient-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.recipient-box {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recipient-box:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.recipient-box.selected {
  border: 2px solid #00796b;
  background-color: #e6ffed;
}

.recipient-box h3 {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.recipient-box svg {
  color: #00796b;
}
