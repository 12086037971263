@font-face {
    font-family: B Koodak Bold_0;
    src: url("../../fonts/B Koodak Bold_0.ttf");
}

@font-face {
  font-family: B-NAZANIN;
  src: url("../../fonts/B-NAZANIN.TTF");
}

.ql-editor {
    direction: rtl;
    text-align: justify;
    font-family: B-NAZANIN;
    font-size: 24px;
  }

  .ql-editor p{
    text-align: right;
    text-align: justify;
  }

  .ql-editor h1,h2,h3,h4,h5,h6{
    text-align: right;
    text-align: justify;
  }

  .ql-editor ol {
    direction: rtl;
    text-align: justify;
}


.ql-editor ul {
  direction: rtl;
  text-align: justify;
  list-style-type: disc;
}

.ql-editor ol li::before {
  margin-left: 20px !important;   /* Adjust if necessary */
}

.ql-editor ul li::before {
  margin-left: 20px !important;   /* Adjust if necessary */
}



@media (max-width: 1000px) {
    .ql-editor {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .ql-editor {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .ql-editor {
        font-size: 16px;
    }
}

.search-input {
    /* padding: 8px; */
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: right;
    color: #979797;
    border: 2px solid #979797;
    border-radius: 50px;
  }

  .search-div {
    position: relative;
  }
  
  .search-icons {
    position: absolute;
    cursor: pointer;
    right: 250px;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-input {
    border: 1px solid;
    transition: border-color 0.3s;
  }
  
  .search-input:focus,
  .search-input:active {
    border: 3px solid #22baa9;
  }
  
  .search-icons {
    color: #979797;
    transition: color 0.3s;
  }
  
  .search-div.focused .purple-icon {
    color: #684fff;
  }

  @media (max-width: 768px) {
    .search-input {
        width: 95%;
      }    

    .search-icons {
        right: 90%;
    }
}

 


  

  