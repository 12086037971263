#teaser {
  width: 100%;
  margin-top: 40px;
  overflow: hidden; /* Ensures no horizontal scrollbars */
}


.logo-container {
  position: absolute;
  top: 10px; /* Adjust the distance from the top as needed */
  left: 100px; /* Adjust the distance from the left as needed */
  z-index: 1; /* Make sure the logo appears above the video */
}

.logo-video {
  width: 80px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .logo-video {
    width: 70px;
  }
  .logo-container {
    left: 70px; /* Adjust the distance from the left as needed */
  }
}

@media screen and (max-width: 768px) {
  .logo-video {
    width: 60px;
  }
  .logo-container {
    left: 40px; /* Adjust the distance from the left as needed */
  }
}

@media screen and (max-width: 600px) {
  .logo-video {
    width: 50px;
  }
  .logo-container {
    left: 30px; /* Adjust the distance from the left as needed */
  }
}

@media screen and (max-width: 400px) {
  .logo-video {
    width: 40px;
  }
  .logo-container {
    left: 20px; /* Adjust the distance from the left as needed */
  }
}

.custom-shape {
  width: 100vw; /* Width of the shape */
  height: 70vh; /* Height of the shape */
  background: linear-gradient(
    45deg,
    #d4e2c8,
    #f2cd89,
    #89e2d4
  ); /* Gradient background */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50% 80%;
  border-bottom-right-radius: 60% 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container-landing-slogan {
  display: flex !important;
  flex-direction: row !important; /* Ensure horizontal layout */
  align-items: center; /* Vertically align content */
  justify-content: space-between !important; /* Add spacing between items */
  width: 90% !important; /* Adjust width as needed */
  gap: 20px;
  margin: auto !important; /* Center content horizontally */
  box-sizing: border-box;
  align-items: flex-start;
  cursor: pointer;
}

.gif-box {
  flex: 1; /* Take equal space */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.gif-box img {
  width: 100%; /* Default width to scale */
  height: auto; /* Maintain aspect ratio */
  max-width: 400px; /* Limit maximum size */
  max-height: auto;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect */
.gif-box img:hover {
  transform: scale(1.1); /* Slightly enlarge the image */
}

.text-box {
  flex: 1; /* Take equal space as gif-box */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center;
  align-items: center;
  position: relative;
  direction: rtl;
}

/* Description Box */
.description-box {
  height: 250px;
  width: 90%;
  padding: 20px;
  border-radius: 50px;
  background: linear-gradient(90deg, #fdfcfb 0%, #e2d1c3 100%);
  text-align: center;
  z-index: 1;
}

.image-box {
  position: absolute;
  bottom: -220px; /* Default overlap position */
  width: 325px;
  height: 325px;
  z-index: 2;
  max-width: 100%; /* Make it responsive within parent */
  border-radius: 100%;
  overflow: hidden;
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center;
}

/* Image inside the box */
.image-box video {
  height: 350px; /* Maintain aspect ratio */
  width: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-box:hover {
  overflow: visible; /* Scale up on hover */
}

.image-box:hover video {
  border-radius: 100%;
  transform: scale(1.1); /* Scale up on hover */
}

@media (max-width: 768px) {
  .content-container-landing-slogan {
    flex-direction: column !important; /* Stack vertically on smaller screens */
    gap: 15px !important; /* Smaller gap */
  }

  .gif-box,
  .text-box {
    width: 100%; /* Make each element take full width */
  }
}

/* Medium Screens - Tablets */
@media (max-width: 1100px) {
  /* Description Box */
  .description-box {
    height: 200px;
    width: 95%;
  }

  .image-box {
    bottom: -200px; /* Default overlap position */
    width: 250px;
    height: 250px;
  }

  /* Image inside the box */
  .image-box video {
    height: 250px; /* Maintain aspect ratio */
  }
}

/* Medium Screens - Tablets */
@media (max-width: 768px) {
  .image-box {
    width: 250px; /* Smaller size for tablets */
    bottom: -200px; /* Adjust overlap */
  }
}

/* Small Screens - Mobile */
@media (max-width: 480px) {
  .image-box {
    width: 200px; /* Even smaller size for mobiles */
    bottom: -150px; /* Reduce overlap */
  }
}

.slick-slider.slick-initialized {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  z-index: 2;
}

.slick-dots {
  position: absolute;
  bottom: 40px;
}

/* Slick Slider Dots */
.slick-dots li button:before {
  font-size: 12px;
  color: white;
}

.slick-dots li.slick-active button:before {
  color: gray;
}

.service-title-h {
  font-weight: 700;
  font-size: 1.1rem; /* Adjust dynamically */
  text-align: center;
  color: #105e44;
  margin-bottom: 2%;
}

/* Responsive Sizes */
@media (max-width: 1100px) {
  .service-title-h {
    font-size: 1rem; /* Slightly smaller for tablets */
  }
}

@media (max-width: 900px) {
  .service-title-h {
    font-size: 0.8rem; /* Slightly smaller for tablets */
  }
}

@media (max-width: 480px) {
  .service-title-h {
    font-size: 0.9rem; /* Even smaller for mobile screens */
  }
}

@media (max-width: 400px) {
  .service-title-h {
    font-size: 0.8rem; /* Even smaller for mobile screens */
  }
}

.service-title-p {
  width: 90%;
  margin: auto;
  font-weight: 400;
  font-size: 1rem; /* Relative font size */
  text-align: justify;
  color: #1e1e1e;
  margin-bottom: 10%;
}

/* Responsive Sizes */
@media (max-width: 1100px) {
  .service-title-p {
    font-size: 0.9rem; /* Adjust for smaller devices */
  }
}

/* Responsive Sizes */
@media (max-width: 900px) {
  .service-title-p {
    font-size: 0.7rem; /* Adjust for smaller devices */
  }
}

@media (max-width: 480px) {
  .service-title-p {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .service-title-p {
    font-size: 0.7rem;
  }
}

@media (max-width: 768px) {
  .slick-dots {
    position: absolute;
    bottom: 20px;
  }
}

/* Container for dots and chevrons */
.custom-dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px; /* Position below slides */
}

/* Custom dot style */
.custom-dot {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: transparent; /* Transparent background */
  border: 3px solid #fff; /* White border */
  background: #fff;
}

.custom-dot:hover {
  background: rgba(255, 255, 255, 0.5); /* Light hover effect */
  cursor: pointer;
}

/* Active dot */
.slick-dots li.slick-active .custom-dot {
  background: linear-gradient(90deg, #84fab0 0%, #8fd3f4 100%);
  border: 3px solid #fff;
}

/* Custom arrow styles */
.custom-arrow {
  font-size: 2rem; /* Chevron size */
  color: #fff; /* Chevron color */
  cursor: pointer;
  user-select: none;
}

/* Arrow hover effect */
.custom-arrow:hover {
  color: #84fab0; /* Highlight color */
}

.slick-list {
  height: 100%;
  overflow: visible !important;
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

.fontawesome-icon-caret {
  color: #37ecba;
}

.fontawesome-icon-caret:hover {
  color: #1dc092;
}

/* Mobile Background - Flat and Clean */
.mobile-background {
  background: linear-gradient(45deg, #d4e2c8, #f2cd89, #89e2d4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Content Container */
.mobile-content-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  /* gap: 40px !important; */
}

/* Medium Screens - Tablets */
@media (max-width: 768px) {
  .slick-slider.slick-initialized {
    margin-top: 1%;
  }

  .gif-box img {
    max-width: 200px; /* Reduce max size */
    max-height: 200px;
  }
}

/* Small Screens - Mobile */
@media (max-width: 480px) {
  .gif-box img {
    max-width: 150px; /* Even smaller size */
    max-height: 150px;
  }

  .gif-box img:hover {
    transform: scale(1.05); /* Subtle hover effect for mobile */
  }
}

.gif-box-mobile {
  width: 300px;
  height: 300px;
  z-index: 2;
  max-width: 100%; /* Make it responsive within parent */
  border-radius: 100%;
  overflow: hidden;
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin-bottom: 2%;
}

/* Image inside the box */
.gif-box-mobile video {
  height: 320px; /* Maintain aspect ratio */
  width: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gif-box-mobile:hover {
  overflow: visible; /* Scale up on hover */
}

.gif-box-mobile:hover video {
  border-radius: 100%;
  transform: scale(1.1); /* Scale up on hover */
}

@media (max-width: 480px) {
  .gif-box-mobile {
    width: 250px;
    height: 250px;
  }

  /* Image inside the box */
  .gif-box-mobile video {
    height: 250px; /* Maintain aspect ratio */
  }
}

/* Feature Boxes Container */
.feature-boxes-container {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

/* Individual Feature Box */
.feature-box {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
  background: #fff;
  display: flex; /* Enables flexbox */
  flex-direction: column; /* Stacks items vertically */
  align-items: center; /* Horizontally centers items */
  justify-content: center; /* Vertically centers items (if height is set) */
  text-align: center;
}

.feature-box:hover {
  transform: translateY(-5px); /* Hover effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.feature-box h3 {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
  direction: rtl;
}

.feature-box svg {
  font-size: 20px;
  color: #37ecba;
}

.feature-box:hover svg {
  color: #1dc092;
}

/* Responsive Design */
@media (max-width: 768px) {
  
  .feature-boxes-container {
    width: 95%;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Show 2 boxes per row on small screens */
  }

  .feature-box h3 {
    font-size: 1rem;
  }

  .feature-box svg {
    font-size: 20px;
  }

  .gif-box-mobile {
    width: 300px;
    height: 300px;
    z-index: 2;
    max-width: 100%; /* Make it responsive within parent */
    border-radius: 100%;
    overflow: hidden;
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin-bottom: 2%;
  }
  
  /* Image inside the box */
  .gif-box-mobile video {
    height: 320px; /* Maintain aspect ratio */
    width: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
}

@media (max-width: 480px) {
  .feature-box h3 {
    font-size: 1rem;
  }
}

@media (max-width: 350px) {
  .feature-box h3 {
    font-size: 0.9rem;
  }

  
  .feature-box svg {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .gif-box-mobile {
    width: 225px;
    height: 225px;
    margin-bottom: 2%;
  }
  
  /* Image inside the box */
  .gif-box-mobile video {
    height: 240px; /* Maintain aspect ratio */
    width: auto;
  }

  .description-box {
    height: 125px;
    width: 95%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .gif-box-mobile {
    width: 200px;
    height: 200px;
    margin-bottom: 2%;
  }
  
  /* Image inside the box */
  .gif-box-mobile video {
    height: 210px; /* Maintain aspect ratio */
    width: auto;
  }

  .description-box {
    height: 175px;
    width: 95%;
    padding: 10px;
  }
  
}