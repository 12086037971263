
@font-face {
    font-family: IranSans;
    src: url("../fonts/IranSans.ttf");
  }
  
  .modal-add-event {
    font-family: IranSans;
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  section.modal-main-add-event{
    position: fixed;
    background-color: white;
    height: auto;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    z-index: 1;
    font-family: IranSans;

    /* height: 50vh; */
  }

  .modal-main-add-event {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 768px) {
    section.modal-main-add-event {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    section.modal-main-add-event {
      width: 95%;
    }
  }

  @media screen and (max-width: 600px) {
    .modal-main-add-event {
      width: 100%;
      max-width: 90%;
    }
  }
  
  .modal-explanation {
    font-family: IranSans;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  section.modal-main-explanation {
    position: fixed;
    background-color: white;
    height: auto;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 1;
    font-family: IranSans;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .explanation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: auto;
    direction: rtl;
    margin-bottom: 20px;
  }
  
  .explanation-header h3 {
    font-size: 1.3rem;
    margin: auto;
  }
  
  .close-explanation {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .explanation-content h4 {
    direction: rtl;
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .explanation-content p {
    direction: rtl;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .close-explanation-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .close-explanation-button:hover {
    background-color: #45a049;
  }
  
  @media screen and (max-width: 768px) {
    section.modal-main-explanation {
      width: 80%;
    }
  }
  
  @media screen and (max-width: 480px) {
    section.modal-main-explanation {
      width: 95%;
    }
  
    .explanation-content h4 {
      font-size: 1.1rem;
    }
  
    .explanation-content p {
      font-size: 0.7rem;
    }
  
    .close-explanation-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  

  div.xmark-font-awsome-right{
    /* float: right; */
    /* margin-right: 2%; */
    color : #b43f3f;
  }
  
  div.xmark-font-awsome-right:hover{
    color : #d06c6c;
  }

  .fontawesome-icon:hover{
    color : #d05959;
  }

  .xmark-font-awsome-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
  }

  button.submit-filter-but{
    align-items: center;
    text-align: center;
    list-style: none;
    cursor:pointer;
    background: none;
    border: none;
    outline: none;
  }
  
  .submit-filter-but {
    background: none;
    border: none;
    cursor: pointer;
  }

  .select-label-figma{
    color: #797979;
    font-size: 15px;
  }

  .select-label-figma1{
    color: #797979;
    font-size: 18px;
  }

  .items-list-div {
    direction: rtl;
    margin-top: 20px;
  }

  ul.items-list-ul{
    direction: rtl;
    text-align: right;
    list-style-type: none;
    width: 100%;
  }

  ul.items-list-ul > li{
    margin: 10px;
    /* align-items: center;
    text-align: center; */
  }

  .items-list-ul {
    list-style: none;
    padding: 0;
  }
  
  .items-list-ul li {
    margin-bottom: 10px;
    color: #333;
  }

  .filter-elements1 {
    padding: 8px;
    /* background-color: #f5f5f5; */
    font-size: 14px;
    color: #797979;
    width: 100%;
    text-align: right;
    border-radius: 10px;
    border: 1px solid #DFDEDE;
    font-family: IranSans;
  }

  @media (max-width: 480px) {
    .filter-elements1 {
      max-width: none;
    }
  }

  .relative-blinker{
    position: relative;
    margin-bottom: 20px;
  }

  div.no-number{
    position: absolute;
    bottom: 0;
  }

  
  p.no-number1{
    font-size: 15px;
    color: red;
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  button.submit-changes{
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    font-family: IranSans;
  }

  button.submit-changes:hover{
    background-color: #7d57f0;
  }

  button.submit-changes-disabled{
    background-color: #959496;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    font-family: IranSans;
  }

  button.thanks-button{
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    font-family: IranSans;
  }

  button.thanks-button:hover{
    background-color: #7d57f0;
  }

  p.thanks-text{
    font-size: 16px;
    font-weight: bold;
    text-align: justify;
    margin-bottom: 15px;
  }

  .form-switch{
    direction: rtl;
  }

  .form-switch-button:nth-child(1){
    margin-left: 2%;
  }
  .form-switch-button:nth-child(2){
    margin-left: 2%;
  }

  .form-switch-button.active{
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    font-family: IranSans;
  }

  .form-switch-button.disable{
    background-color: #959496;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    font-family: IranSans;
  }

  .checkbox-both{
    display: flex;
    gap: 5%;
    margin: auto;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 45px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff8080;
    transition: .4s;
  }
  
  .slider.round {
    border-radius: 45px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 37px; /* Adjust height */
    width: 37px; /* Adjust width */
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #3e9e53;
  }
  
  input:checked + .slider:before {
    transform: translateX(55px); /* Adjust transform for the new width */
  }
  
  .slider .tick, .slider .cross {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px; /* Adjust font size */
    color: white;
  }
  
  .slider .tick {
    left: 10px;
  }
  
  .slider .cross {
    right: 10px;
  }

  @media screen and (max-width: 768px) {
    p.no-number1{
      font-size: 10px;
    }
  }

  button.submit-changes-form{
    background-color: #6336F0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 10px;
    font-family: IranSans;
  }

  button.submit-changes-form:hover{
    background-color: #7d57f0;
  }
  
  .error {
    color: #D8000C; /* Red color for error text */
    background-color: #FFBABA; /* Light red background for visibility */
    padding: 5px;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-top: 5px;
    width: fit-content;
}

.modal-confetti {
    font-family: IranSans;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.confetti-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.required {
  color: red;
  margin-right: 3px;
  font-weight: bold;
  font-size: 15px;
}
