:root {
    --bg-dark: #1b212d;
    --text-dark: #eff3fd;
    --border-dark: #2a3240;
    --highlight-dark: #fead53;
    --success-dark: #4caf50;
    --holding-dark: #f39c12; /* New color for holding state */
  }
  
  .reaction-test-game-container {
    background-color: var(--bg-dark);
    color: var(--text-dark);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
  }
  
  .reaction-test-box {
    width: 225px;
    height: 150px;
    border: 3px solid var(--border-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    /* transition: background-color 0.3s ease, transform 0.2s ease; */
  }
  
  .reaction-test-upper-box {
    position: relative;
    background-color: var(--border-dark);
  }
  
  .reaction-test-upper-box.reaction-test-active {
    background-color: var(--success-dark);
  }
  
  .reaction-test-lower-box {
    background-color: var(--highlight-dark);
  }
  
  .reaction-test-lower-box.reaction-test-holding {
    background-color: var(--holding-dark);
  }
  
  .reaction-test-spacer {
    height: 50px;
  }
  
  .reaction-test-results {
    margin-top: 20px;
    padding: 15px;
    color: black;
    border-radius: 10px;
    text-align: center;
  }
  
  .reaction-test-results p {
    font-size: 16px;
  }

  .reaction-test-foul {
    background-color: var(--foul-dark);
    color: white;
    font-weight: bold;
  }
  
  .finish-game-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--highlight-dark);
    border: none;
    color: white;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .finish-game-button:hover {
    background-color: #d35400;
  }
  
  .hand-animation-reaction {
    position: absolute;
    width: 100px;
    height: auto;
    transition: transform 1s ease-in-out;
  }
  
  .move-up {
    transform: translateY(-200px); /* Moves up into the upper box */
  }
  
  .actual-game-reaction{
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .try-again-text{
    text-align: justify;
    direction: rtl;
    font-size: 16px;
    color: #eff3fd;
  }

  .corsi-light-theme .try-again-text {
    color: var(--corsi-text-color-light);
  }

  .pointer-icon{
    margin-right: 15px;
  }