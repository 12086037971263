@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body::-webkit-scrollbar {
  width: 0.4em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(45deg, #d4e2c8, #f2cd89, #89e2d4);
  outline: 1px solid slategrey;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-DemiBold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-ExtraBold.woff2");
  font-weight: 800;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Heavy.woff2");
  font-weight: 900;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-Thin.woff2");
  font-weight: 100;
}

@font-face {
  font-family: "IRANSansXVF";
  src: url("fonts/IRANSansX-UltraLight.woff2");
  font-weight: 200;
}


@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-DemiBold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-ExtraBold.woff2");
  font-weight: 800;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Heavy.woff2");
  font-weight: 900;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-Thin.woff2");
  font-weight: 100;
}

@font-face {
  font-family: "IRANSansXFaNum";
  src: url("fonts/IRANSansXFaNum-UltraLight.woff2");
  font-weight: 200;
}