@font-face {
  font-family: IranSans;
  src: url("../fonts/IranSans.ttf");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #0b093b;
}
a:hover {
  color: #128b7d;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #0b093b;
  font-family: "IranSans", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}

.container-map-location {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 0px;
}

@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

.extraBold-title {
  font-weight: 800 !important;
}
/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  font-family: "IranSans", sans-serif;

  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid #22baa9;
  color: #7620ff;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  text-align: justify;
  font-size: 0.813rem;
}

.font-footer {
  text-align: center;
  font-size: 0.813rem;
}

.font14 {
  font-size: 0.875rem;
}
.font15 {
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font20-title {
  font-size: 1.1rem;
  text-align: center;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 20px;
}
.font60 {
  font-size: 2.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 1000px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font-footer {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }

  .font20-title {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 1.8rem;
  }
}

/* Smaller screens (max-width: 720px) */
@media only screen and (max-width: 720px) {
  .font40 {
    font-size: 1.625rem; /* 26px */
  }
}

/* Even smaller screens (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .font40 {
    font-size: 1.5rem; /* 24px */
  }
}

/* Smallest screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .font40 {
    font-size: 1.375rem; /* 22px */
  }
}

/* Extra small screens (max-width: 360px) */
@media only screen and (max-width: 360px) {
  .font40 {
    font-size: 1.25rem; /* 20px */
  }
}

/* Smaller screens (max-width: 720px) */
@media only screen and (max-width: 720px) {
  .font60 {
    font-size: 1.6rem; /* 25.6px */
    line-height: 2rem; /* 32px */
  }
}

/* Even smaller screens (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .font60 {
    font-size: 1.4rem; /* 22.4px */
    line-height: 1.8rem; /* 28.8px */
  }
}

/* Smallest screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .font60 {
    font-size: 1.2rem; /* 19.2px */
    line-height: 1.5rem; /* 24px */
  }
}

/* Extra small screens (max-width: 360px) */
@media only screen and (max-width: 360px) {
  .font60 {
    font-size: 1rem; /* 16px */
    line-height: 1.25rem; /* 20px */
  }
}

/* Smaller screens (max-width: 720px) */
@media only screen and (max-width: 720px) {
  .font20 {
    font-size: 1rem; /* 16px */
  }
}

/* Even smaller screens (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .font20 {
    font-size: 0.875rem; /* 14px */
  }
}

/* Smallest screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .font20 {
    font-size: 0.75rem; /* 12px */
  }
}

/* Extra small screens (max-width: 360px) */
@media only screen and (max-width: 360px) {
  .font20 {
    font-size: 0.625rem; /* 10px */
  }
}

/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}

.radius-about-us {
  border-radius: 100%;
  width: 100%; /* Allow the image to take up the full width of its container */
  max-width: 250px; /* Limit the maximum width */
  height: auto; /* Maintain the aspect ratio */
  border: 4px solid #1c176e;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  transform: perspective(1000px) rotateY(0deg);
}

.radius-about-us:hover {
  transform: translateY(-10px) rotateZ(2deg) scale(1.05); /* Float up, tilt, and slightly zoom */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Add a soft shadow for depth */
}

/* Ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .radius-about-us {
    max-width: 300px; /* Adjust max-width for smaller devices */
  }
}

@media (max-width: 480px) {
  .radius-about-us {
    max-width: 250px; /* Further adjust for mobile screens */
  }
}

@media (max-width: 400px) {
  .radius-about-us {
    max-width: 200px; /* Further adjust for mobile screens */
  }
}

.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #0b093b;
}
.purpleColor {
  color: #7620ff;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {
  background-color: #0b093b;
}
.purpleBg {
  background-color: #7620ff;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff;
}

.green-text {
  color: #22baa9;
}

.background-component {
  text-align: center;
  position: relative;
  /* margin-top: 80px; */
  /* background-image: linear-gradient(to right, #0b687d 50%, #be3d3d 50%); */
}

.background-component video {
  margin: auto;
  width: 90vw;
  height: calc(100vh - 80px); /* Adjust the height value as needed */
  object-fit: fill;
  opacity: 1;
}

@media (max-width: 768px) {
  .background-component video {
    width: 100vw;
    height: auto; /* Adjust the height value as needed */
    object-fit: fill;
    opacity: 0.8;
  }
}

@media (max-width: 480) {
  .background-component video {
    width: 100vw;
    height: calc(40vh); /* Adjust the height value as needed */
    object-fit: fill;
    opacity: 0.8;
  }
}

.background-component .slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .background-component .slogan {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .background-component .slogan {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .background-component .slogan {
    font-size: 10px;
  }
}

.background-component .stop-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.background-component .stop-button:hover {
  /* background-color: rgba(255, 255, 255, 1); */
}

.background-component .resume-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.background-component .resume-button:hover {
  /* background-color: rgba(255, 255, 255, 1); */
}

.header-text {
  text-align: justify;
}
.header-div {
  /* margin-right: 20%; */
  direction: rtl;
}

@media (min-width: 960px) {
  .header-div {
    margin-right: 20%;
  }
}

.services-div {
  direction: rtl;
}

.services-text {
  text-align: justify;
}

.blog-div {
  direction: rtl;
}

.blog-posts {
  text-align: justify;
}

.quotes {
  direction: rtl;
  text-align: justify;
}

.medical-icons {
  text-align: center;
}

.about-us-div {
  direction: rtl;
}

.locations-div {
  direction: rtl;
}

.custom-box {
  width: 18%;
  margin-right: 1%; /* Add some space between boxes */
  display: inline-block;
  vertical-align: top; /* Align boxes to the top */
  /* margin: auto; */
}

@media (max-width: 768px) {
  .custom-box {
    width: 48%; /* Adjust for smaller screens */
    margin-bottom: 2%;
  }
}

@media (max-width: 576px) {
  .custom-box {
    width: 98%; /* Adjust for extra small screens */
    margin-bottom: 2%;
  }
}

.mx-auto {
  direction: rtl;
}

.category-blog {
  margin: auto;
  text-align: center;
  align-items: center;
}

.ql-editor,
.ql-container,
.quill {
  direction: rtl !important;
  text-align: right;
}

.ql-editor p {
  text-align: right;
  text-align: justify;
}

.ql-editor img {
  text-align: center;
  margin: auto;
}

.ltr-i18n {
  direction: ltr;
}

.icon-class {
  color: #0b093b; /* Adjust icon color if needed */
}

.Toastify__toast-container {
  text-align: right;
}

.Toastify__toast-container div {
  font-family: IranSans !important;
}

.title-services {
  margin: auto;
  text-align: center;
}

.title-services-icon {
  margin: auto !important;
  text-align: center !important;
}
