/* HealthProfilePanel.css */
.Cagmo-health-profile-container {
    text-align: center;
    margin: 20px;
  }
  
  .Cagmo-toggle-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Cagmo-toggle-btn:hover {
    background-color: #45a049;
  }
  
  .Cagmo-health-profile-panel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 90%;
    max-width: 400px;
  }
  
  .Cagmo-close-panel {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
  }
  
  .Cagmo-form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .Cagmo-step-content {
    text-align: left;
  }
  
  .Cagmo-input-field {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    direction: rtl;
  }
  
  .Cagmo-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Cagmo-next-btn {
    background-color: #2196F3;
    color: white;
  }
  
  .Cagmo-next-btn:hover {
    background-color: #1e88e5;
  }
  
  .Cagmo-prev-btn {
    background-color: #f1f1f1;
    color: #333;
  }
  
  .Cagmo-prev-btn:hover {
    background-color: #ddd;
  }
  
  .Cagmo-submit-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .Cagmo-submit-btn:hover {
    background-color: #45a049;
  }

  .Cagmo-pre-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100vh; /* Center vertically */
    background-color: #f4f4f9; /* Optional background color */
  }
  
  .Cagmo-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Space between the logos */
  }
  
  .Cagmo-logo {
    width: 100px;
    height: auto;
  }

  .Cagmo-step-content h2 {
    direction: rtl;
    text-align: right; /* Optional: Align text to the right */
  }
  .Cagmo-cooperation-icon {
    font-size: 35px; /* Adjust icon size as needed */
    color: #4CAF50; /* Green color for the icon */
    vertical-align: middle ; /* Ensure icon is vertically aligned */
  }

  .Cagmo-button-panel {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px;
  }
  