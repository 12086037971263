@font-face {
    font-family: IranSans;
    src: url("../fonts/IranSans.ttf");
  }
  
  @keyframes popupAnimation {
    from {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
  
  section.modal-main-logout {
    position: fixed;
    background-color: white;
    height: auto;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%; /* Default width for larger screens */
    padding: 30px 20px;
    animation: popupAnimation 0.3s ease-out forwards;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    direction: rtl;
    text-align: center;
  }
  
  div.modal-logout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
  }
  
  .logout-ul {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  button.logout-yes, button.logout-no {
    font-family: IranSans;
    font-size: 16px;
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
  }
  
  button.logout-yes {
    background: linear-gradient(45deg, #ff6b6b, #ff0000);
    color: white;
    border: none;
    box-shadow: 0 4px 12px rgba(255, 107, 107, 0.4);
  }
  
  button.logout-yes:hover {
    background: linear-gradient(45deg, #ff4a4a, #ff0000);
    box-shadow: 0 6px 16px rgba(255, 107, 107, 0.6);
  }
  
  button.logout-no {
    background: white;
    color: #808080;
    border: 2px solid #808080;
  }
  
  button.logout-no:hover {
    color: black;
    border-color: black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .all-logout {
    text-align: center;
    margin: auto;
  }
  
  p.logout-text {
    font-family: IranSans;
    font-weight: 500;
    font-size: 20px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .change-text {
    font-family: IranSans;
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
  }
  
  .logout-divider {
    border: none;
    width: 80%;
    margin: 20px auto;
    border-top: 1px solid #ddd;
  }
  
  /* Responsive styles */
  @media (max-width: 1100px) {
    section.modal-main-logout {
      width: 90%; /* Wider for mobile devices */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
    }
  
    p.logout-text {
      font-size: 18px; /* Slightly smaller font for mobile */
    }
  
    .change-text {
      font-size: 12px;
    }
  
    button.logout-yes, button.logout-no {
      font-size: 14px;
      padding: 10px 20px; /* Smaller padding for mobile */
    }
  
    .logout-ul {
      margin: auto;
      margin-top: 2%;
      width: fit-content;
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px; /* Add some space between buttons */
    }
  }
  
  @media (max-width: 480px) {
    section.modal-main-logout {
      width: 95%; /* Full width for very small screens */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
    }
  
    .logout-ul {
        margin: auto;
        margin-top: 2%;
        width: 100%;
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px; /* Add some space between buttons */
      }

    button.logout-yes, button.logout-no {
      width: 100%; /* Full width buttons for easier touch on small screens */
      padding: 12px 0;
      font-size: 12px;
    }
  }
  