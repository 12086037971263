.fallrisk-rtl-text {
    text-align: right;
    direction: rtl;
    font-family: IranSans;
}

.fallrisk-body {
    background-color: #f0f2f5;
    font-family: IranSans;
}

.fallrisk-chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    font-family: IranSans;
}

.fallrisk-chat-window {
    width: 100%;
    max-width: 400px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 500px; /* Fixed height */
}

.fallrisk-chat-body {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    background: #e5ddd5;
    display: flex;
    flex-direction: column;
    max-height: 400px; /* Ensures scrolling */
}

/* Beautiful Scrollbar */
.fallrisk-chat-body::-webkit-scrollbar {
    width: 8px;
}

.fallrisk-chat-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.fallrisk-chat-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.fallrisk-chat-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fallrisk-chat-header {
    background: #075E54;
    padding: 15px;
    color: #fff;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.fallrisk-intro {
    text-align: center;
    margin-bottom: 20px;
}

.fallrisk-intro input {
    padding: 10px;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-bottom: 10px;
}

.fallrisk-intro button {
    background: #25D366;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s;
}

.fallrisk-intro button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.fallrisk-message {
    max-width: 80%;
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 20px;
    position: relative;
    font-size: 14px;
    line-height: 1.4;
    display: inline-block;
}

/* Align System Messages (Questions) to the Right */
.fallrisk-received {
    background: #fff;
    color: #000;
    align-self: flex-start;
    text-align: right;
    /* text-align: justify; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Align User Messages (Answers) to the Left */
.fallrisk-sent {
    background: #dcf8c6;
    color: #000;
    align-self: flex-end;
    text-align: left;
    /* text-align: justify; */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.fallrisk-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.fallrisk-yes-button, .fallrisk-no-button {
    background: #34b7f1;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background 0.3s;
}

.fallrisk-yes-button:hover, .fallrisk-no-button:hover {
    background: #30a5d8;
}

.fallrisk-loading {
    text-align: center;
    color: #999;
}

.fallrisk-chat-footer {
    display: flex;
    padding: 10px;
    border-top: 1px solid #f0f0f0;
    background: #fff;
}

.fallrisk-chat-footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    outline: none;
}

.fallrisk-send-button {
    background: #25D366;
    border: none;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;
}

.fallrisk-send-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.fallrisk-send-button:hover:not(:disabled) {
    background: #1da851;
}
